// ----------------------------------------------------------------------

export default function Typography(theme) {
  return {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h2',
          h2: 'h2',
          h3: 'h2',
          h4: 'h2',
          h5: 'h2',
          h6: 'h2',
          h13: 'h1',
          h14: 'h1',
          subtitle1: 'h2',
          subtitle2: 'h2',
        },
      },
      styleOverrides: {
        // root: {
        //   h6: { color: theme.palette.text.test },
        // },
        h6: { color: theme.palette.text.primary },
        body1: { color: theme.palette.text.primary },
        body2: { color: theme.palette.text.secondary },

        paragraph: {
          marginBottom: theme.spacing(2),
        },
        gutterBottom: {
          marginBottom: theme.spacing(1),
        },
      },
    },
  };
}
