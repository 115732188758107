import qs from 'qs';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
// pins
import pinRed from '../../assets/images/icons/map/pin1W.svg';
import pinBlue from '../../assets/images/icons/map/pin2W.svg';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isLoadingSights: false,
  error: null,
  articles: [],
  sights: [],
  currentArticle: {},
  navHeight: 0,
  mapCategories: {},
  totalCount: 0,
};

const slice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    startSightLoading(state) {
      state.isLoadingSights = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetArticlesSuccess(state) {
      state.isLoading = false;
      state.articles = [];
    },
    // GET ARTICLES
    getArticlesSuccess(state, action) {
      state.isLoading = false;
      const temp = action.payload.results;
      temp.forEach((item) => {
        item.icon = item.articleType === 1 ? pinRed : pinBlue;
      });
      state.articles = temp;
      state.totalCount = action.payload.totalCount;
    },

    resetArticlesSuccessSight(state) {
      state.isLoadingSights = false;
      state.sights = [];
    },

    getArticlesSuccessSight(state, action) {
      state.isLoadingSights = false;
      const temp = action.payload.results;
      temp.forEach((item) => {
        item.icon = item.articleType === 1 ? pinRed : pinBlue;
      });
      state.sights = temp;
      state.totalCount = action.payload.totalCount;
    },

    getCurrentArticleSuccess(state, action) {
      state.isLoading = false;
      state.currentArticle = action.payload;
    },

    setNavHeight(state, action) {
      state.isLoading = false;
      state.navHeight = action.payload;
    },

    getCategoriesSuccess(state, action) {
      state.mapCategories = action.payload;
      state.isLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getArticlesSuccess, getArticlesSuccessSight } = slice.actions;

// ----------------------------------------------------------------------

export function getArticles() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/articles', { params: { size: 1000000 } });
      dispatch(slice.actions.getArticlesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function resetMapArticles() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // console.log(response.data)
      return dispatch(slice.actions.resetArticlesSuccess());
    } catch (error) {
      return dispatch(slice.actions.hasError(error));
    }
  };
}

export function resetMapArticlesSight() {
  return async () => {
    dispatch(slice.actions.startSightLoading());
    try {
      // console.log(response.data)
      return dispatch(slice.actions.resetArticlesSuccessSight());
    } catch (error) {
      return dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMapArticles(categories) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/article/search`, {
        params: {
          ...categories,
          searchText: categories?.searchText?.replaceAll("\"", ""),
          searchByKeywords: !categories?.searchText?.includes("\""),
          articleTypes: 2,
          sortAttribute: 'title',
          size: 1000000,
        },
        paramsSerializer: (params) => qs.stringify(params),
      });
      // console.log(response.data)
      return dispatch(slice.actions.getArticlesSuccess(response.data));
    } catch (error) {
      return dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMapArticlesSight(categories) {
  return async () => {
    dispatch(slice.actions.startSightLoading());
    try {
      const response = await axios.get(`/article/search`, {
        params: {
          ...categories,
          searchText: categories?.searchText?.replaceAll("\"", ""),
          searchByKeywords: !categories?.searchText?.includes("\""),
          articleTypes: 1,
          sortAttribute: 'title',

          size: 1000000,
        },
        paramsSerializer: (params) => qs.stringify(params),
      });
      // console.log(response.data)
      return dispatch(slice.actions.getArticlesSuccessSight(response.data));
    } catch (error) {
      return dispatch(slice.actions.hasError(error));
    }
  };
}

export function getArticle(key) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/article/${key}`);
      dispatch(slice.actions.getCurrentArticleSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setNavigationHeight(h) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // console.log(h);
      // const response = await axios.get(`/article/${key}`);
      dispatch(slice.actions.setNavHeight(h));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
