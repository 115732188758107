import qs from 'qs';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  tagsLoading: false,
  error: null,
  filterOptions: [],
  filterEDuOptions: [],
  filterTags: [
    { key: '0' },
    { key: '1' },
    { key: '2' },
    { key: '3' },
    { key: '4' },
    { key: '5' },
    { key: '6' },
    { key: '7' },
    { key: '8' },
    { key: '9' },
    { key: '10' },
    { key: '11' },
    { key: '12' },
  ],
  filterMapOptions: [{ key: '30' }, { key: '40' }],
  searchResults: [],
  tagCache: {},
  catsCache: {},
};

const slice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    startTagsLoading(state) {
      state.tagsLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ARTICLES
    getFiltersSuccess(state, action) {
      function compare(a, b) {
        if (a.count > b.count) {
          return -1;
        }
        if (a.count < b.count) {
          return 1;
        }
        return 0;
      }

      action.payload.tags.forEach((tag) => tag.results.sort(compare));
      action.payload.categories.forEach((category) => {
        // reccursive calling compare function
        category.results.forEach((cat) => {
          cat.subCategories.sort(compare);
          cat.subCategories.forEach((subCat) => {
            if (subCat.subCategories.length > 0) {
              subCat.subCategories.sort(compare);
            }
          });
        });
      });
      const temp = [];
      action.payload.categories.forEach((category) => category.key !== '4' && temp.push(...category.results));
      state.filterOptions = temp;
      // state.filterTags = action.payload.tags;
      state.isLoading = false;
    },

    getFiltersCompaniesSuccess(state, action) {
      function compare(a, b) {
        if (a.count > b.count) {
          return -1;
        }
        if (a.count < b.count) {
          return 1;
        }
        return 0;
      }

      action.payload.tags.forEach((tag) => tag.results.sort(compare));
      action.payload.categories.forEach((category) => {
        // reccursive calling compare function
        category.results.forEach((cat) => {
          cat.subCategories.sort(compare);
          cat.subCategories.forEach((subCat) => {
            if (subCat.subCategories.length > 0) {
              subCat.subCategories.sort(compare);
            }
          });
        });
      });
      const temp = [];
      action.payload.categories.forEach((category) => category.key === '4' && temp.push(...category.results));
      state.filterOptions = temp;
      // state.filterTags = action.payload.tags;
      state.isLoading = false;
    },

    getFiltersUnsortedSuccess(state, action) {
      state.filterOptions = action.payload.categories;
      state.filterTags = action.payload.tags;
      state.isLoading = false;
    },

    getFiltersMapSuccess(state, action) {
      state.isLoading = false;
      const temp = [];
      action.payload.categories.forEach((category) => category.key !== '5' && temp.push(...category.results));
      state.filterOptions = temp;
      state.filterTags = action.payload.tags;
    },

    // getFiltersCompaniesSuccess(state, action) {
    //   state.isLoading = false;
    //   state.filterOptions = action.payload[0].children[0].children;
    //   // state.filterTags = action.payload.tags;
    // },
    getTagsListSuccess(state, action) {
      // const sortedTags = action.payload.map((tag) => {
      //   const sortedResults = tag.results.sort((a, b) => b.count - a.count);
      //   return { ...tag, results: sortedResults };
      // });
      const sortedTags = action.payload.results.sort((a, b) => b.count - a.count);
      const tagIndex = state.filterTags.findIndex((item) => item.key === action.payload.key);
      state.filterTags[tagIndex] = { ...action.payload, results: sortedTags };
      state.tagsLoading = false;
    },

    getCatsListSuccess(state, action) {
      // const sortedTags = action.payload.map((tag) => {
      //   const sortedResults = tag.results.sort((a, b) => b.count - a.count);
      //   return { ...tag, results: sortedResults };
      // });
      state.filterOptions = action.payload.results;
      state.isLoading = false;
    },
    getCatsEduListSuccess(state, action) {
      // const sortedTags = action.payload.map((tag) => {
      //   const sortedResults = tag.results.sort((a, b) => b.count - a.count);
      //   return { ...tag, results: sortedResults };
      // });
      state.filterEDuOptions = action.payload.results;
      state.isLoading = false;
    },

    getMapCatsListSuccess(state, action) {
      // const sortedTags = action.payload.map((tag) => {
      //   const sortedResults = tag.results.sort((a, b) => b.count - a.count);
      //   return { ...tag, results: sortedResults };
      // });
      const tagIndex = state.filterMapOptions.findIndex((item) => item.key === action.payload.results?.[0]?.key);

      state.filterMapOptions[tagIndex] = { ...action.payload.results?.[0] };

      state.isLoading = false;
    },

    clearFilters(state) {
      state.isLoading = false;
      state.tagsLoading = false;
      state.error = null;
      state.filterOptions = [];
      state.filterTags = [];
    },
    getSearchArticlesSuccess(state, action) {
      state.isLoading = false;
      state.searchResults = action.payload;
    },
    getTagByKeySuccess(state, action) {
      state.tagCache[action.payload.key] = action.payload;
    },
    getCatCacheSuccess(state, action) {
      state.catsCache[action.payload.key] = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getFiltersSuccess, clearFilters } = slice.actions;

// ----------------------------------------------------------------------

export function addCatToCache(catKey, cache) {
  return async () => {
    try {
      if (!cache[catKey]) {
        const response = await axios.get(`/category/${catKey}`);
        return dispatch(slice.actions.getCatCacheSuccess(response.data));
      }
      return null;
    } catch (error) {
      return dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTagByKey(tagKey, cache) {
  return async () => {
    try {
      if (!cache[tagKey]) {
        const response = await axios.get(`/tag/${tagKey}`);
        return dispatch(slice.actions.getTagByKeySuccess(response.data));
      }
      return null;
    } catch (error) {
      return dispatch(slice.actions.hasError(error));
    }
  };
}

export function putTagByKey(tag, locale, cache) {
  return async () => {
    try {
      if (!cache[tag.key]) {
        return dispatch(
          slice.actions.getTagByKeySuccess({
            count: tag.count,
            enabled: true,
            key: tag.key,
            name: { [locale]: tag.name },
            order: 0,
            tagTypeKey: tag.tagTypeKey,
          })
        );
      }
      if (!cache[tag.key].name[locale]) {
        return dispatch(
          slice.actions.getTagByKeySuccess({
            count: tag.count,
            enabled: true,
            key: tag.key,
            name: { ...cache[tag.key].name, [locale]: tag.name },
            order: 0,
            tagTypeKey: tag.tagTypeKey,
          })
        );
      }
      return null;
    } catch (error) {
      return dispatch(slice.actions.hasError(error));
    }
  };
}

// TODO: ked bude public endpoint zmenit ho a upravit!

export function getMediaFilters(categoryTypeKey) {
  return async () => {
    try {
      const response = await axios.get('/categories', {
        params: { categoryTypeKey },
      });
      return dispatch(slice.actions.getFiltersSuccess(response.data));
    } catch (error) {
      return dispatch(slice.actions.hasError(error));
    }
  };
}

// ARTICLES
export function getCategoriesArticles(locale) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/article/filters', { params: { locale } });
      return dispatch(slice.actions.getFiltersSuccess(response.data));
    } catch (error) {
      return dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCategoriesCompanies(locale = 'skSK') {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/article/filters', { params: { locale } });
      return dispatch(slice.actions.getFiltersCompaniesSuccess(response.data));
    } catch (error) {
      return dispatch(slice.actions.hasError(error));
    }
  };
}

export function getArticleTagsList({ tagTypeKey, autocompleteText, size, page, locale = 'skSK', filter }) {
  return async () => {
    dispatch(slice.actions.startTagsLoading());
    try {
      const response = await axios.get(`/article/filter/tag`, {
        params: { tagTypeKey, autocompleteText, size, page, locale, ...filter },
        paramsSerializer: (params) => qs.stringify(params),
      });
      dispatch(slice.actions.getTagsListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// EDU MATERIALS

export function getCategoriesEDUs({ categoryTypeKey, autocompleteText, size, page, locale, filter }) {
  return async () => {
    dispatch(slice.actions.startTagsLoading());
    try {
      const response = await axios.get(`/edumaterial/filter/category`, {
        params: { categoryTypeKey, autocompleteText, size, page, locale, ...filter },
        paramsSerializer: (params) => qs.stringify(params),
      });
      dispatch(slice.actions.getCatsEduListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getEduTagsList({ tagTypeKey, autocompleteText, size, page }) {
  return async () => {
    dispatch(slice.actions.startTagsLoading());
    try {
      const response = await axios.get(`/edumaterial/filter/${tagTypeKey}`, {
        params: { autocompleteText, size, page },
      });
      dispatch(slice.actions.getTagsListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// VIDEOLIBRARY
export function getCategoriesMultimedia(locale = 'skSK') {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/videolibrary/filters', { params: { locale } });
      return dispatch(slice.actions.getFiltersSuccess(response.data));
    } catch (error) {
      return dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMediaTagsList({ tagTypeKey, autocompleteText, size, page }) {
  return async () => {
    dispatch(slice.actions.startTagsLoading());
    try {
      const response = await axios.get(`/videolibrary/filter/${tagTypeKey}`, {
        params: { autocompleteText, size, page },
      });
      dispatch(slice.actions.getTagsListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// CULTURAL OBJECTS
export function getCategoriesCulturalObjects() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/culturalobject/filters');
      return dispatch(slice.actions.getFiltersSuccess(response.data));
    } catch (error) {
      return dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCategoriesCulturalObjectsFilters({ locale }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/culturalobject/filters', { params: { locale } });
      return dispatch(slice.actions.getFiltersUnsortedSuccess(response.data));
    } catch (error) {
      return dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCulturalTagsList({ tagTypeKey, autocompleteText, size, page, locale, filter }) {
  return async () => {
    dispatch(slice.actions.startTagsLoading());
    try {
      const response = await axios.get(`/culturalobject/filter/tag`, {
        params: { tagTypeKey, autocompleteText, size, page, locale, ...filter },
        paramsSerializer: (params) => qs.stringify(params),
      });
      dispatch(slice.actions.getTagsListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMapCatsList({ ArticleTypes, categoryTypeKey, autocompleteText, size, page, locale, filter }) {
  return async () => {
    dispatch(slice.actions.startTagsLoading());
    try {
      const response = await axios.get(`/article/filter/category`, {
        params: {
          ArticleTypes,
          categoryTypeKey,
          autocompleteText,
          size,
          page,
          locale,
          ...filter,
        },
        paramsSerializer: (params) => qs.stringify(params),
      });
      dispatch(slice.actions.getMapCatsListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getArticleCatsList({ ArticleTypes, categoryTypeKey, autocompleteText, size, page, locale, filter }) {
  return async () => {
    dispatch(slice.actions.startTagsLoading());
    try {
      const response = await axios.get(`/article/filter/category`, {
        params: {
          ArticleTypes,
          categoryTypeKey,
          autocompleteText,
          size,
          page,
          locale,
          ...filter,
        },
        paramsSerializer: (params) => qs.stringify(params),
      });
      dispatch(slice.actions.getCatsListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCulturalCatsList({ categoryTypeKey, autocompleteText, size, page, locale, filter }) {
  return async () => {
    dispatch(slice.actions.startTagsLoading());
    try {
      const response = await axios.get(`/culturalobject/filter/category`, {
        params: { categoryTypeKey, autocompleteText, size, page, locale, ...filter },
        paramsSerializer: (params) => qs.stringify(params),
      });
      dispatch(slice.actions.getCatsListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getVideoCatsList({ categoryTypeKey, autocompleteText, size, page, locale, filter }) {
  return async () => {
    dispatch(slice.actions.startTagsLoading());
    try {
      const response = await axios.get(`/videolibrary/filter/category`, {
        params: { categoryTypeKey, autocompleteText, size, page, locale, ...filter },
        paramsSerializer: (params) => qs.stringify(params),
      });
      dispatch(slice.actions.getCatsListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCategoriesMap(locale = 'skSK') {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/article/filters', { params: { locale } });
      return dispatch(slice.actions.getFiltersMapSuccess(response.data));
    } catch (error) {
      return dispatch(slice.actions.hasError(error));
    }
  };
}

// export function getCategoriesCompanies() {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.get(`admin/categories`, {
//         params: { rootKey: 40 },
//         paramsSerializer: (params) => qs.stringify(params),
//       });
//       return dispatch(slice.actions.getFiltersCompaniesSuccess(response.data));
//     } catch (error) {
//       return dispatch(slice.actions.hasError(error));
//     }
//   };
// }

export function getSafeSearch({ searchText, articleTypes, type }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/${type}/search`, {
        params: { 
          size: 7, 
          articleTypes, 
          searchText: searchText?.replaceAll("\"", ""),
          searchByKeywords: !searchText?.includes("\"") 
        },
        paramsSerializer: (params) => qs.stringify(params),
      });
      dispatch(slice.actions.getSearchArticlesSuccess(response.data.results));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
