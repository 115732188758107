import { MenuItem, FormControl } from '@mui/material';
import { styled } from '@mui/material/styles';
import { GAEvent } from '../../GoogleAnalytics';
import useLocales from '../../../hooks/useLocales';
import { ReactComponent as SkFlag } from '../../../assets/images/icons/SKflag.svg';
import { ReactComponent as USFlag } from '../../../assets/images/icons/USFlag.svg';

// const FlagSelect = styled(Select)(() => ({
//   fontWeight: 'bold',
//   display: 'inline-block',
//   padding: '0px',
//   height: 'auto',
//   margin: '6px',
//   marginLeft: '0px',
//   width: '60px',
//   '& div': {
//     padding: '0px',
//     paddingRight: '0px !important',
//   },
//   '& .MuiSelect-icon': {
//     display: 'none',
//   },
//   '& fieldset': {
//     border: 'none',
//   },
//   '& .MuiOutlinedInput-input': {
//     overflow: 'unset',
//     display: 'flex',
//     alignItems: 'center',
//   },
// }));

const FlagText = styled('span')(({ theme }) => ({
  fontWeight: 'bold',
  display: 'inline-block',
  verticalAlign: 'top',
  marginLeft: theme.spacing(1),
  fontSize: '14px',
  textTransform: 'uppercase',
  paddingTop: '0px',
}));

// const FlagIcon = styled('span')(({ theme }) => ({
//   display: 'inline-block',
//   verticalAlign: 'top',
//   // borderRadius: '3px',
//   paddingTop: theme.spacing(1),
// }));

export default function LanguageSelect() {
  const { onChangeLang, currentLang } = useLocales();
  const changeLanguageEN = () => {
    onChangeLang('en');
    GAEvent('Jazykova Vlajka', 'Klik na Vlajku', 'Jazykova Vlajka Komponent');
  };

  const changeLanguageSK = () => {
    onChangeLang('sk');
    GAEvent('Jazykova Vlajka', 'Klik na Vlajku', 'Jazykova Vlajka Komponent');
  };

  // const correctFlag = (lang) => {
  //   switch (lang) {
  //     case 'en':
  //       return 'us';
  //     default:
  //       return lang;
  //   }
  // };

  // const menuItems = allLangs.map((lang) => (
  //   <MenuItem key={lang.value} value={lang.value}>
  //     {lang.value === 'sk' ? <SkFlag /> : <FlagIcon className={`fi fi-${correctFlag(lang.value)}`}> </FlagIcon>}
  //     <FlagText sx={{ color: 'text.primary' }}>{lang.value}</FlagText>
  //   </MenuItem>
  // ));

  return (
    <FormControl size="small" sx={{ color: 'black' }}>
      {currentLang.value === 'sk' && (
        <MenuItem
          key={'sk'}
          role={'none'}
          value={'sk'}
          onClick={changeLanguageEN}
          sx={{
            padding: '0px',
            marginTop: '8px',
            marginBottom: '6px',
            marginRight: '16px',
            minHeight: '0 !important',
            pt: { md: '3px !important', sm: '0', xs: '0' },
          }}
        >
          <USFlag role={'none'} />
          <FlagText role={'none'} sx={{ color: 'text.primary' }}>
            {'en'}
          </FlagText>
        </MenuItem>
      )}

      {currentLang.value === 'en' && (
        <MenuItem
          key={'en'}
          role={'none'}
          value={'en'}
          onClick={changeLanguageSK}
          sx={{
            padding: '0px',
            marginTop: '8px',
            marginBottom: '6px',
            marginRight: '16px',
          }}
        >
          {/* <FlagIcon className={`fi fi-${correctFlag('en')}`}> </FlagIcon> */}

          <SkFlag role={'none'} />
          <FlagText role={'none'} sx={{ color: 'text.primary' }}>
            {'sk'}
          </FlagText>
        </MenuItem>
      )}
    </FormControl>
  );
}
