import { CheckboxIcon, CheckboxCheckedIcon, CheckboxIndeterminateIcon } from './CustomIcons';
// ----------------------------------------------------------------------

export default function Checkbox(theme) {
  return {
    MuiCheckbox: {
      defaultProps: {
        icon: <CheckboxIcon />,
        checkedIcon: <CheckboxCheckedIcon />,
        indeterminateIcon: <CheckboxIndeterminateIcon />,
      },

      styleOverrides: {
        root: {
          stroke: theme.palette.primaryButton.checkBoxPrimary,
          color: theme.palette.primaryButton.checkboxFill,
          padding: theme.spacing(1),

          '&.Mui-checked': {
            // fill: theme.palette.primaryButton.checkboxFill,
            color: theme.mode === 'dark' ? 'transparent' : theme.palette.primaryButton.checkboxFill,
          },
          '&.Mui-checked.Mui-disabled, &.Mui-disabled': {
            color: theme.palette.action.selected,
          },
          '& .MuiSvgIcon-fontSizeMedium': {
            width: 24,
            height: 24,
          },
          '& .MuiSvgIcon-fontSizeSmall': {
            width: 20,
            height: 20,
          },
          svg: {
            fontSize: 24,
            '&[font-size=small]': {
              fontSize: 20,
            },
          },
          '&.MuiCheckbox-colorSecondary': {
            stroke: theme.palette.grey[900],
            fill: theme.palette.grey[200],
            '& .MuiSvgIcon-root': {
              fill: 'inherit',
            },
          },
        },
      },
    },
  };
}
