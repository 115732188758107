import { lazy } from 'react';
import { m } from 'framer-motion';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container } from '@mui/material';
// components
import Page from '../components/Page';
import { MotionContainer, varBounce } from '../components/animate';
// assets

// import { MaintenanceIllustration } from '../assets';
import useLocales from '../hooks/useLocales';

const MaintenanceIllustration = lazy(() => import('../assets/illustration_maintenance'));
const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function PageHealthCheck() {
  const { translate } = useLocales();

  const refresh = () => {
    window.location.reload();
  };

  return (
    <Page title="Server is down">
      <Container component={MotionContainer}>
        <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
          <m.div variants={varBounce().in}>
            <Typography variant="h3" paragraph>
              {translate('healthcheck.title')}
            </Typography>
          </m.div>

          <m.div variants={varBounce().in}>
            <Typography sx={{ color: 'text.secondary' }}>{translate('healthcheck.message')}</Typography>
          </m.div>

          <m.div variants={varBounce().in}>
            <MaintenanceIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
          </m.div>

          <Button size="large" variant="contained" onClick={refresh}>
            Refresh
          </Button>
        </ContentStyle>
      </Container>
    </Page>
  );
}
