import PropTypes from 'prop-types';
import { Box, Modal, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import css from './Modals.module.css';
import Iconify from '../../Iconify';

CustomModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.element,
  sx: PropTypes.object,
};

export default function CustomModal({ open, onClose, children, sx }) {
  const theme = useTheme();

  const style = {
    bgcolor: 'background.header',
    border: `3px solid ${theme.palette.background.neutral}`,
    outline: 0,
  };

  return (
    <Modal open={open} onClose={onClose} sx={{ outline: 0 }}>
      <Box sx={{ ...style, ...sx }} className={`${css.modal} ${css.modalSize}`}>
        <Typography variant="body2" className={css.closeIcon} onClick={onClose}>
          <Iconify icon="fa:close" />
        </Typography>
        {children}
      </Box>
    </Modal>
  );
}
