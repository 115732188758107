import { createSlice } from "@reduxjs/toolkit";
import qs from 'qs';
import axios from '../../utils/axios';
import { dispatch } from "../store";

const initialState = {
  isLoading: false,
  page: null,
  hasError: false,
  carousel: [],
}

const slice = createSlice({
  name: 'customPage',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.hasError = action.payload;
      state.isLoading = false;
    },

    getPageSuccess(state, action) {
      state.page = action.payload;
      if (action.payload?.carousel?.items?.length > 0) {
        state.carousel = action.payload.carousel.items;
      }
      state.isLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;
// //////////////////////////////////////////////////////////////

export function getPageContent(key, locale) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // needs to change after translation is done
      const response = await axios.get(`/custompage/${key}`, { 
        params: { locale }, 
        paramsSerializer: (params) => qs.stringify(params)
      });
      dispatch(slice.actions.getPageSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}