/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useCookies, Cookies } from 'react-cookie';
import ReactGA from 'react-ga4';

ReactGA.initialize(process.env.REACT_APP_GA_CODE);

export default function GoogleAnalytics(props) {
  const [cookies] = useCookies();

  useEffect(() => {
    const script = document.createElement('script');
    if (cookies.cookies === 'true') {
      script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA_CODE}`;
      script.async = true;
      document.body.appendChild(script);

      const win = window;
      win.dataLayer = win.dataLayer || [];
      // eslint-disable-next-line no-inner-declarations
      function gtag(...par) {
        // eslint-disable-next-line prefer-rest-params
        win.dataLayer.push(arguments);
      }
      gtag('js', new Date());

      gtag('config', process.env.REACT_APP_GA_TRACKING_CODE);
    }
    return () => {
      if (cookies.cookies === 'true') {
        document.body.removeChild(script);
      }
    };
  }, [cookies.cookies]);

  // eslint-disable-next-line react/prop-types
  return <>{props.children}</>;
}

export function GAEvent(category, action, label) {
  const cookies = new Cookies();
  if (cookies.get('cookies') === 'true' || localStorage.getItem('cookies') === 'true') {
    ReactGA.event({
      category,
      action,
      label,
    });
  }
}
