import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { IconButtonAnimate } from '../animate';
import Iconify from '../Iconify';
import useSettings from '../../hooks/useSettings';

ModeSettingsSwitch.propTypes = {
  inMenu: PropTypes.bool,
};

export default function ModeSettingsSwitch({ inMenu = false }) {
  const size = 20;

  const { themeMode, onChangeMode } = useSettings();

  const modeSwitchHandler = () => {
    onChangeMode({ target: { value: themeMode === 'light' ? 'dark' : 'light' } });
  };

  if (inMenu) {
    return (
      <div style={{ display: 'inline-block' }}>
        <Switcher
          onClick={modeSwitchHandler}
          sx={{ justifyContent: themeMode === 'light' ? 'flex-start' : 'flex-end' }}
        >
          <SwitchBackground active={themeMode !== 'light' ? 'true' : 'false'} size={size} />
          {themeMode === 'light' ? (
            <SwitchIcon icon={'eva:sun-fill'} width={size} height={size} />
          ) : (
            <SwitchIcon icon={'eva:moon-fill'} width={size} height={size} />
          )}
        </Switcher>
      </div>
    );
  }

  return (
    <IconButtonAnimate
      onClick={modeSwitchHandler}
      sx={{
        fontSize: '3.0rem',
        color: themeMode === 'light' ? 'text.secondary' : '#001',
      }}
    >
      <Iconify icon={themeMode === 'light' ? 'tabler:bulb-off' : 'tabler:bulb'} />
    </IconButtonAnimate>
  );
}

const Switcher = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  borderRadius: '13px',
  backgroundColor: theme.palette.background.neutral,
  boxShadow: `1px 1px 5px ${theme.palette.grey[500]}`,
  marginRight: theme.spacing(3),
  paddingTop: '0px',
  paddingBottom: '0px',
  height: '26px',
  width: '56px',
  marginTop: '6px',
  marginLeft: '8px',
  cursor: 'pointer',
  transition: '1s',
}));

const SwitchIcon = styled(Iconify)(({ theme }) => ({
  position: 'relative',
  marginTop: '3px',
  marginLeft: theme.spacing(0.5),
  marginRight: theme.spacing(0.5),
  color: 'primary.inverse',
}));

const SwitchBackground = styled('div')(({ size, active = 'false', theme }) => ({
  position: 'absolute',
  top: '4px',
  left: active === 'false' ? '34px' : '6px',
  backgroundColor: theme.palette.text.primary,
  width: `${size - 2}px`,
  height: `${size - 2}px`,
  borderRadius: '50%',
  // transition: '0.6s',
}));
