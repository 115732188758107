import { Toolbar, AppBar, Box } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useEffect, useRef } from 'react';
import { HEADER } from '../../config';
import useOffSetTop from '../../hooks/useOffSetTop';
import useResponsive from '../../hooks/useResponsive';
import Logo from '../Logo';
import navConfig, { topRoutesConfig as topNavConfig } from '../../routes/config';
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import { setNavigationHeight } from '../../redux/slices/map';
import { dispatch } from '../../redux/store';

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: HEADER.MOBILE_HEIGHT,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('md')]: {
    height: HEADER.MAIN_DESKTOP_HEIGHT,
  },
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 'auto',
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8,
}));

// ----------------------------------------------------------------------

export default function CustomHeader() {
  const ref = useRef(null);

  useEffect(() => {
    dispatch(setNavigationHeight(ref.current.clientHeight));
  }, []);

  const isOffset = useOffSetTop(HEADER.MAIN_DESKTOP_HEIGHT);

  const theme = useTheme();

  const isDesktop = useResponsive('up', 'lg');

  const bigLogo = useResponsive('up', 'sm');

  return (
    <AppBar
      ref={ref}
      id="header"
      sx={{
        // boxShadow: `0px 2px 5px 2px ${theme.palette.background.darker}`,
        // bgcolor: isOffset ? 'transparent' : theme.palette.background.header,
        bgcolor: theme.palette.background.header,
      }}
    >
      <ToolbarStyle
        disableGutters
        sx={{
          height: 'auto !important',
          p: 1,
          ...(isOffset &&
            {
              // ...cssStyles(theme).bgBlur(),
              // height: { md: HEADER.MAIN_DESKTOP_HEIGHT - 16 },
            }),
        }}
      >
        <Logo big={bigLogo} />
        <Box sx={{ flexGrow: 1 }} />
        {isDesktop && <MenuDesktop isOffset={isOffset} navConfig={navConfig} topNavConfig={topNavConfig} />}
        {!isDesktop && <MenuMobile isOffset={isOffset} navConfig={navConfig} topNavConfig={topNavConfig} />}
      </ToolbarStyle>
      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}
