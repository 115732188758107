import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  stories: {
    totalCount: '',
    results: [],
  },
  currentStory: {},
};

const slice = createSlice({
  name: 'story',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Annoucement
    getStoriesSuccess(state, action) {
      state.isLoading = false;
      state.stories = action.payload;
    },

    logState(state, action) {
      console.log(state, action);
    },
  },
});

// Reducer
export default slice.reducer;

// GET
export function getStories({ size, page, sortAttribute = '', sortDirection = 0, locale }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/stories', {
        params: { size, page, sortAttribute, sortDirection, locale },
      });
      dispatch(slice.actions.getStoriesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function logState() {
  dispatch(slice.actions.logState());
}
