import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
// import mailReducer from './slices/mail';
// import chatReducer from './slices/chat';
import articlesReducer from './slices/articles';
import companyReducer from './slices/company';
import annoucementsReducer from './slices/annoucement';
import storiesReducer from './slices/story';
import mapReducer from './slices/map';
import favouritesReducer from './slices/favourites';
import filtersReducer from './slices/filters';
import mediaReducer from './slices/media';
import eduReducer from './slices/edu';
import COReducer from './slices/culturalObject';
import sheetsReducer from './slices/sheets';
import customPageReducer from './slices/customPage';
// import productReducer from './slices/product';
// import calendarReducer from './slices/calendar';
// import kanbanReducer from './slices/kanban';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};
const favouritesConfig = {
  key: 'favourites',
  storage,
  keyPrefix: '',
};

const rootReducer = combineReducers({
  // mail: mailReducer,
  // chat: chatReducer,
  // calendar: calendarReducer,
  // kanban: kanbanReducer,
  articles: articlesReducer,
  company: companyReducer,
  media: mediaReducer,
  annoucement: annoucementsReducer,
  story: storiesReducer,
  map: mapReducer,
  favourites: persistReducer(favouritesConfig, favouritesReducer),
  filters: filtersReducer,
  edu: eduReducer,
  culturalObject: COReducer,
  sheets: sheetsReducer,
  customPage: customPageReducer,
  // product: persistReducer(productPersistConfig, productReducer),
});

export { rootPersistConfig, rootReducer };
