const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
  favourites: [],
};

const slice = createSlice({
  name: 'favourites',
  initialState,
  reducers: {
    addToFavourites(state, action) {
      if (action.payload.guid) {
        action.payload.key = action.payload.guid;
        delete action.payload.guid;
      }
      const { key } = action.payload;
      const isExist = state.favourites.filter((item) => item.key === key);
      if (isExist.length === 0) {
        state.favourites = [...state.favourites, {...action.payload, added: Date.now(), name: action.payload.name? action.payload.name : action.payload.title}];
      } else state.favourites = state.favourites.filter((item) => item.key !== key);
    },
    deleteAllFavourites(state) {
      state.favourites = [];
    },
    sortFavorites(state, action) {
      const { key, order } = action.payload;
			if (key !== 'name') {
        state.favourites = state.favourites.sort((a, b) => {
          if (order === '1') {
            if (a[key] > b[key]) return 1;
            if (a[key] < b[key]) return -1;
          } else {
            if (a[key] < b[key]) return 1;
            if (a[key] > b[key]) return -1;
          }
          return 0;
        });
      }

      if (key === 'name') {
        state.favourites = state.favourites.sort((a, b) => {
          if (order === '0') {
            if (a[key] > b[key]) return 1;
            if (a[key] < b[key]) return -1;
          } else {
            if (a[key] < b[key]) return 1;
            if (a[key] > b[key]) return -1;
          }
          return 0;
        });
      }
    }
  },
});

// Reducer
export default slice.reducer;

// Actions

export const { sortFavorites, addToFavourites, deleteAllFavourites } = slice.actions;
