// ----------------------------------------------------------------------

export default function Input(theme) {
  return {
    MuiInputBase: {
      styleOverrides: {
        minHeight: '48px',
        root: {
          // height: '40px',
          '&.Mui-disabled': {
            border: '1px solid rgba(0, 0, 0, 0.3)',

            '& svg': { color: theme.palette.text.disabled },
          },
        },
        input: {
          '&::placeholder': {
            opacity: 1,
            color: theme.palette.text.disabled,
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&&:hover::before': {
            borderColor: 'red',
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          border: '1px solid rgba(0, 0, 0, 0.3)',
          backgroundColor: 'white',
          '&:hover': {
            backgroundColor: 'white',
            //   borderBottom: 'none',
            //  borderBottomColor: 'none',
          },
          '&:after': {
            // borderBottom: 'none',
          },
          '&.Mui-focused': {
            backgroundColor: 'white',
          },
          '&.Mui-disabled': {
            backgroundColor: theme.palette.action.disabledBackground,
          },
        },
        // underline: {
        //   '&&:hover::before': {
        //     display: 'none',
        //   },
        //   '&:before': {
        //     display: 'none',
        //   },
        //   '&:after': {
        //     display: 'none',
        //   },
        //   '&:hover': {
        //     display: 'none',
        //   },
        // },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.grey[200],
          color: 'rgba(0, 0, 0, 0.65)',
          // height: '48px',
          '&.Mui-focused fieldset': {
            borderColor: `${theme.palette.primaryButton.main} !important`,
          },

          '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid rgba(0, 0, 0, 0.3)',
          },
          '&.Mui-disabled': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.action.disabledBackground,
            },
            '& .Mui-focused': {
              backgroundColor: theme.palette.primaryButton.main,
            },
          },
        },
      },
    },
  };
}
