import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import { Link, Stack } from '@mui/material';
import useLocales from '../../hooks/useLocales';
import LanguageSelect from '../custom/LanguageSelect/LanguageSelect';
import ModeSettingsSwitch from './ModeSettingsSwitch';
import Iconify from '../Iconify';
import { useSelector } from '../../redux/store';

const LinkStyle = styled(Link)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.primary,
  textDecoration: 'none',
  marginRight: theme.spacing(5),
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
  bgcolor: theme.palette.background.header,
  '&:hover': {
    /*  color: 'action.active',
    boxShadow: '0px -2px 0px black inset',
    fontWeight: '900',  */
    textDecoration: 'none',
  },
}));

MenuDesktop.propTypes = {
  isOffset: PropTypes.bool,
  navConfig: PropTypes.array,
  topNavConfig: PropTypes.array,
};

export default function MenuDesktop({ isOffset, navConfig, topNavConfig }) {
  return (
    <Stack direction="column" spacing={2}>
      {/* {!isOffset && ( */}
      <Stack direction="row-reverse" alignItems="center" sx={{ mb: '8px !important' }}>
        <ModeSettingsSwitch inMenu />
        <LanguageSelect />
        {topNavConfig.map((link) => (
          <MenuItemTop key={link.title} item={link} isOffset={isOffset} smaller />
        ))}
      </Stack>
      {/* )} */}
      <Stack direction="row" sx={{ mt: '0px !important' }}>
        {navConfig.map((link) => (link.display ? <MenuItem key={link.title} item={link} isOffset={isOffset} /> : null))}
      </Stack>
    </Stack>
  );
}

// ----------------------------------------------------------------------

MenuItem.propTypes = {
  isOffset: PropTypes.bool,
  item: PropTypes.shape({
    path: PropTypes.string,
    pathT: PropTypes.string,
    title: PropTypes.string,
    icon: PropTypes.any,
    showIcon: PropTypes.bool,
  }),
  smaller: PropTypes.bool,
};

const ICON_SIZE = {
  width: 22,
  height: 22,
};

function MenuItem({ item, isOffset, smaller }) {
  const { translate } = useLocales();
  const theme = useTheme();
  const { title, path, pathT, showIcon } = item;
  const { favourites } = useSelector((state) => state.favourites);

  const handleClick = () => {
    if (window.location.pathname === path) {
      const urlWithoutParams = window.location.origin + window.location.pathname;
      window.history.replaceState({}, document.title, urlWithoutParams);
      window.location.reload();
    }
  };

  return (
    <LinkStyle
      className={`hover-underline-animation ${theme.palette.mode === 'dark' ? ' und-bl' : ' und-wh'}`}
      to={window.location.pathname.includes('en/') ? `en${translate(pathT)}` : translate(pathT)}
      component={RouterLink}
      end={path === '/'}
      onClick={handleClick}
      sx={{
        position: 'relative',
        textTransform: 'uppercase',
        mr: 3,
        mb: 1,
        mt: 0.8,
        fontSize: smaller ? '0.875rem' : 'inherit',
        fontWeight: '600',
        ...(isOffset && { color: 'text.primary' }),
        '&.active': {
          color: 'text.primary',
          boxShadow: theme.palette.mode === 'dark' ? '0px -2px 0px white inset' : '0px -2px 0px black inset',
          fontWeight: '900',
        },
      }}
    >
      {title === 'default.favorites' && favourites?.length > 0 && showIcon && (
        <Iconify
          icon={'ant-design:heart-filled'}
          color="#D16666"
          sx={{ mr: 1, position: 'relative', verticalAlign: 'bottom' }}
          {...ICON_SIZE}
        />
      )}
      {title === 'default.favorites' && favourites?.length === 0 && showIcon && (
        <Iconify
          icon={'ant-design:heart-filled'}
          sx={{ mr: 1, position: 'relative', verticalAlign: 'bottom' }}
          {...ICON_SIZE}
        />
      )}
      {translate(title)}
    </LinkStyle>
  );
}

MenuItemTop.propTypes = {
  isOffset: PropTypes.bool,
  item: PropTypes.shape({
    path: PropTypes.string,
    pathT: PropTypes.string,
    title: PropTypes.string,
    icon: PropTypes.any,
    showIcon: PropTypes.bool,
  }),
  smaller: PropTypes.bool,
};

function MenuItemTop({ item, isOffset, smaller }) {
  const { translate } = useLocales();
  const theme = useTheme();
  const { title, path, pathT, showIcon } = item;
  const { favourites } = useSelector((state) => state.favourites);

  const handleClick = () => {
    if (window.location.pathname === path) {
      const urlWithoutParams = window.location.origin + window.location.pathname;
      window.history.replaceState({}, document.title, urlWithoutParams);
      window.location.reload();
    }
  };
  return (
    <LinkStyle
      className={`hover-underline-animation ${theme.palette.mode === 'dark' ? ' und-bl-top' : ' und-wh-top'}`}
      to={window.location.pathname.includes('en/') ? `en${translate(pathT)}` : translate(pathT)}
      component={RouterLink}
      end={path === '/'}
      onClick={handleClick}
      sx={{
        position: 'relative',
        textTransform: 'uppercase',
        mr: 3,
        mb: 1,
        pt: 1.45,
        color: theme.palette.mode === 'dark' ? '#a6a6a6' : '#777777',
        fontSize: smaller ? '0.875rem' : 'inherit',
        fontWeight: '600',
        ...(isOffset && { color: theme.palette.mode === 'dark' ? '#a6a6a6' : '#777777' }),
        '&.active': {
          color: theme.palette.mode === 'dark' ? '#a6a6a6' : '#737373',
          boxShadow: theme.palette.mode === 'dark' ? '0px -2px 0px #737373 inset' : '0px -2px 0px #a5a5a5 inset',
          fontWeight: '900',
        },
      }}
    >
      {title === 'default.favorites' && favourites?.length > 0 && showIcon && (
        <Iconify
          icon={'ant-design:heart-filled'}
          color="#D16666"
          sx={{ mr: 1, position: 'relative', verticalAlign: 'bottom' }}
          {...ICON_SIZE}
        />
      )}
      {title === 'default.favorites' && favourites?.length === 0 && showIcon && (
        <Iconify
          icon={'ant-design:heart-filled'}
          sx={{ mr: 1, position: 'relative', verticalAlign: 'bottom' }}
          {...ICON_SIZE}
        />
      )}
      {translate(title)}
    </LinkStyle>
  );
}

/* ===================== MENU ITEM WITH SUBMENU
MenuDesktopItem.propTypes = {
  isHome: PropTypes.bool,
  isOffset: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  item: PropTypes.shape({
    path: PropTypes.string,
    title: PropTypes.string,
    children: PropTypes.array,
  }),
};

function MenuDesktopItem({ item, isHome, isOpen, isOffset, onOpen, onClose }) {
  const { pathname } = useLocation();

  const { title, path, children } = item;

  const isActive = (path) => pathname === path;

  if (children) {
    return (
      <>
        <LinkStyle
          onClick={onOpen}
          sx={{
            display: 'flex',
            cursor: 'pointer',
            alignItems: 'center',
            ...(isHome && { color: 'common.white' }),
            ...(isOffset && { color: 'text.primary' }),
            ...(isOpen && { opacity: 0.48 }),
          }}
        >
          {title}
          <Iconify
            icon={isOpen ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
            sx={{ ml: 0.5, width: 16, height: 16 }}
          />
        </LinkStyle>

        <Popover
          open={isOpen}
          anchorReference="anchorPosition"
          anchorPosition={{ top: 80, left: 0 }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={onClose}
          PaperProps={{
            sx: {
              px: 3,
              pt: 5,
              pb: 3,
              right: 16,
              m: 'auto',
              borderRadius: 2,
              maxWidth: (theme) => theme.breakpoints.values.lg,
              boxShadow: (theme) => theme.customShadows.z24,
            },
          }}
        >
          <Grid container spacing={3}>
            {children.map((list) => {
              const { subheader, items } = list;

              return (
                <Grid key={subheader} item xs={12} md={subheader === 'Dashboard' ? 6 : 2}>
                  <List disablePadding>
                    <ListSubheader
                      disableSticky
                      disableGutters
                      sx={{
                        display: 'flex',
                        lineHeight: 'unset',
                        alignItems: 'center',
                        color: 'text.primary',
                        typography: 'overline',
                      }}
                    >
                      <IconBullet type="subheader" /> {subheader}
                    </ListSubheader>

                    {items.map((item) => (
                      <SubLinkStyle
                        key={item.title}
                        href={item.path}
                        sx={{
                          ...(isActive(item.path) && {
                            color: 'text.primary',
                            typography: 'subtitle2',
                          }),
                        }}
                      >
                        {item.title === 'Dashboard' ? (
                          <CardActionArea
                            sx={{
                              py: 5,
                              px: 10,
                              borderRadius: 2,
                              color: 'primary.main',
                              bgcolor: 'background.neutral',
                            }}
                          >
                            <Box
                              component={m.img}
                              whileTap="tap"
                              whileHover="hover"
                              variants={{
                                hover: { scale: 1.02 },
                                tap: { scale: 0.98 },
                              }}
                              src="/assets/illustrations/illustration_dashboard.png"
                            />
                          </CardActionArea>
                        ) : (
                          <>
                            <IconBullet />
                            {item.title}
                          </>
                        )}
                      </SubLinkStyle>
                    ))}
                  </List>
                </Grid>
              );
            })}
          </Grid>
        </Popover>
      </>
    );
  }

  return (
    <LinkStyle
      to={path}
      component={RouterLink}
      end={path === '/'}
      sx={{
        ...(isHome && { color: 'common.white' }),
        ...(isOffset && { color: 'text.primary' }),
        '&.active': {
          color: 'primary.main',
        },
      }}
    >
      {title}
    </LinkStyle>
  );
}
*/
