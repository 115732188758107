import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Button, Tooltip } from '@mui/material';
import Iconify from '../../Iconify';
import useLocales from '../../../hooks/useLocales';

GoUppButton.propTypes = {
  style: PropTypes.object,
};

export default function GoUppButton({ style = {} }) {
  const theme = useTheme();
  const [visible, setVisible] = useState(false);
  const { translate } = useLocales();

  const toggleVisible = () => {
    if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisible, false);
    return () => {
      window.removeEventListener('scroll', toggleVisible, false);
    };
  }, []);

  const handleScroll = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <Tooltip title={translate('default.goUp')}>
      <Button
        // className="hidden-xs"
        aria-label="goUp"
        style={{
          display: visible ? 'block' : 'none',
          backgroundColor: theme.palette.mode === 'dark' ? 'white' : 'black',
          color: theme.palette.mode === 'dark' ? 'black' : 'white',
          opacity: '0.9',
          position: 'fixed',
          width: '50px',
          height: '50px',
          minWidth: '50px',
          borderRadius: '10px',
          padding: '0px',
          border: 'solid 2px #CCC',
          bottom: '50px',
          right: '18px',
          zIndex: '480',
          ...style,
        }}
        variant="contained"
        onClick={handleScroll}
      >
        <Iconify icon={'fa:chevron-up'} />
      </Button>
    </Tooltip>
  );
}
