import { createSlice } from '@reduxjs/toolkit';
import qs from 'qs';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  companies: { totalCount: '', results: [] },
  filters: {},
  relatedCo: [],
  currentCompany: null,
  prev: null,
  next: null,
};

const slice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getFiltersSuccess(state, action) {
      state.isLoading = false;
      state.filters = action.payload;
    },

    getRelatedCoSuccess(state, action) {
      state.isLoading = false;
      state.relatedCo = action.payload;
    },

    resetRelatedCoSuccess(state) {
      state.isLoading = false;
      state.relatedCo = [];
    },

    // GET COMPANIES
    getCompaniesSuccess(state, action) {
      state.isLoading = false;
      state.relatedCo = [];
      if (action.payload.loadMore && !action.payload.sortAttribute) {
        state.companies.totalCount = action.payload.totalCount;
        state.companies.hasMoreResults = action.payload.hasMoreResults;
        state.companies.results = [...state.companies.results, ...action.payload.results];
      } else {
        delete action.payload.sortAttribute;
        delete action.payload.loadMore;
        state.companies = action.payload;
      }
      state.currentCompany = {};
    },

    getCurrentCompanySuccess(state, action) {
      state.isLoading = false;
      state.relatedCo = [];
      state.currentCompany = action.payload;
    },
    getAdjacentSuccess(state, action) {
      state.prev = action.payload.previous;
      state.next = action.payload.next;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getArticlesSuccess } = slice.actions;

// ----------------------------------------------------------------------

// export function getCompanies({ searchText, size, filter, sort, page, tagKeys }) {
//   // HOTFIX na pole v params
//   const categoryItems = JSON.parse(filter || 'null')?.category?.map((item) => item.param);
//   const categoryKeys = `${categoryItems?.length > 0 ? '&categoryKeys=' : ''}${categoryItems?.join('&categoryKeys=')}`;
//   // KONIEC HOTFIXU
//   // SORT
//   let sortDirection;
//   let sortAttribute;
//   if (sort) {
//     sortDirection = sort.charAt(0) === '!' ? 1 : 0;
//     sortAttribute = sort.replace('!', '');
//   }
//   // KONIEC SORTU
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.get(`/article/search?${categoryKeys}`, {
//         params: {
//           searchText,
//           size,
//           page: page - 1,
//           sortDirection,
//           tagKeys,
//           sortAttribute,
//           searchByKeywords: false,
//         },
//         paramsSerializer: (params) => qs.stringify(params),
//       });
//       dispatch(slice.actions.getCompaniesSuccess(response.data));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

export function getCompanyListForInput(searchText, size) {
  return async () => {
    try {
      const response = await axios.get(`/article/search`, {
        params: {
          sortAttribute: 'rank',
          sortDirection: 1,
          size,
          page: 0,
          searchText: searchText?.replaceAll("\"", ""),
          searchByKeywords: !searchText?.includes("\""),
          articleTypes: [2],
        },
        paramsSerializer: (params) => qs.stringify(params),
      });
      return response;
    } catch (error) {
      return dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCompanies({
  loadMore,
  searchText,
  size,
  sortDirection,
  sortAttribute,
  page,
  categoryKeys,
  tagKeys,
  locale,
  // searchByKeywords,
  signal,
}) {
  // let sortDirection;
  // let sortAttribute;
  // if (sort) {
  //   sortDirection = sort.charAt(0) === '!' ? 1 : 0;
  //   sortAttribute = sort.replace('!', '');
  // }

  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/article/search`, {
        params: {
          articleTypes: [2],
          sortAttribute,
          sortDirection,
          size,
          page: page - 1,
          categoryKeys,
          tagKeys,
          searchText: searchText?.replaceAll("\"", ""),
          searchByKeywords: !searchText?.includes("\""),
          locale,
        },
        signal,
        paramsSerializer: (params) => qs.stringify(params),
      });
      dispatch(slice.actions.getCompaniesSuccess({ ...response.data, loadMore, sortAttribute }));
    } catch (error) {
      if (!signal.aborted) {
        dispatch(slice.actions.hasError(error));
      }
    }
  };
}

export function getCompaniesCount({ searchText, categoryKeys, tagKeys }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/article/search/totalCount', {
        params: {
          articleTypes: [2],
          categoryKeys,
          tagKeys,
          searchText: searchText?.replaceAll("\"", ""),
          searchByKeywords: !searchText?.includes("\""),
        },
        paramsSerializer: (params) => qs.stringify(params),
      });
      dispatch(slice.actions.getVideoCountSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCompaniesFilter(max = 50) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/article/filters', {
        params: {
          tagsLimit: max,
        },
      });
      dispatch(slice.actions.getFiltersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCompany(key, locale) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/article/${key}`, {
        params: {
          locale,
        },
        paramsSerializer: (params) => qs.stringify(params),
      });
      dispatch(slice.actions.getCurrentCompanySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRelatedCo(key) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/folder/${key}`);
      dispatch(slice.actions.getRelatedCoSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function resetRelatedCo() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.resetRelatedCoSuccess());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCompanyAdjacent(
  index,
  searchText,
  sortAttribute,
  sortDirection,
  categoryKeys,
  tagKeys,
  // searchByKeywords
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/article/search/adjacent`, {
        params: {
          index,
          sortAttribute,
          sortDirection,
          categoryKeys,
          tagKeys,
          searchText: searchText?.replaceAll("\"", ""),
          searchByKeywords: !searchText?.includes("\""),
          articleTypes: [2],
        },
        paramsSerializer: (params) => qs.stringify(params),
      });
      dispatch(slice.actions.getAdjacentSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
