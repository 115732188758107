import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import Iconify from '../Iconify';

const Link = styled('a')(({ theme }) => ({
  ...theme.typography.h2,
  color: 'unset',
  display: 'inline-block',
  marginRight: theme.spacing(1.5),
  marginTop: theme.spacing(1),
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
  '&:hover': {
    opacity: 0.825,
    textDecoration: 'none',
  },
}));

CustomFooterIcon.propTypes = {
  icon: PropTypes.any,
  link: PropTypes.string,
};

export default function CustomFooterIcon({ icon, link }) {
  return (
    <Link href={link} target="_blank" rel="noreferrer" aria-label={link}>
      <div
        style={{
          backgroundColor: 'white',
          borderRadius: '100px',
          width: '50px',
          height: '50px',
          position: 'relative',
        }}
      >
        <Iconify
          icon={icon}
          style={{
            color: '#0D0F12',
            fontSize: '26px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      </div>
    </Link>
  );
}
