import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

const PRIMARY_BLUE = {
  ultraLight: '#FFEC54',
  lighter: '#FFDF45',
  light: '#FFD136',
  main: '#FFC425',
  dark: '#E1AA00',
  darker: '#D29D00',
};
const PRIMARY_YELLOW = {
  ultraLight: '#FFEC54',
  lighter: '#FFDF45',
  light: '#FFD136',
  main: '#FFC425',
  dark: '#E1AA00',
  darker: '#D29D00',
};
const SECONDARY = {
  lighter: '#000000',
  light: '#000000',
  main: '#E5E5E5',
  dark: '#000000',
  darker: '#000000',
};
const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
};
const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#54D62C',
  dark: '#229A16',
  darker: '#08660D',
};
const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
};
const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E',
};

/*
const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8),
};
*/
const GREY = {
  0: '#FFFFFF',
  100: '#F7F9FA',
  200: '#F0F0F0',
  300: '#CDD1D3',
  400: '#AAAEB1',
  500: '#7F7F7F',
  600: '#64696D',
  700: '#41474B',
  800: '#1E2429',
  900: '#181C20',
  1000: '#000000',
  500_8: alpha('#7F7F7F', 0.08),
  500_12: alpha('#7F7F7F', 0.12),
  500_16: alpha('#7F7F7F', 0.16),
  500_24: alpha('#7F7F7F', 0.24),
  500_32: alpha('#7F7F7F', 0.32),
  500_48: alpha('#7F7F7F', 0.48),
  500_56: alpha('#7F7F7F', 0.56),
  500_80: alpha('#7F7F7F', 0.8),
  1000_65: alpha('#000', 0.65),
  1000_90: alpha('#000', 0.9),
};

const GRADIENTS = {
  primary: createGradient(PRIMARY_BLUE.light, PRIMARY_BLUE.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
};

const COMMON = {
  common: { black: GREY[1000], white: GREY[0] },
  // primary: { ...PRIMARY, contrastText: GREY[0] },
  // secondary: { ...SECONDARY, contrastText: GREY[0] },
  info: { ...INFO, contrastText: GREY[0] },
  success: { ...SUCCESS, contrastText: GREY[800] },
  warning: { ...WARNING, contrastText: GREY[800] },
  error: { ...ERROR, contrastText: GREY[0] },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_48],
  action: {
    hover: GREY[500_8],
    selected: GREY[500_80],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

const palette = {
  light: {
    ...COMMON,
    primary: { ...PRIMARY_BLUE, contrastText: GREY[900] },
    primaryButton: { ...PRIMARY_BLUE, contrastText: GREY[900], checkBoxPrimary: GREY[900], checkboxFill: GREY[200] },
    secondary: { ...SECONDARY, contrastText: GREY[900] },
    mode: 'light',
    text: {
      primary: GREY[1000_90],
      secondary: GREY[1000_65],
      disabled: GREY[500],
      inverse: GREY[200],
      inverseLight: GREY[300],
    },
    background: { header: GREY[0], paper: GREY[0], default: GREY[200], neutral: GREY[200], darker: GREY[300] },
    invertedBackground: { paper: GREY[800], default: GREY[1000], neutral: GREY[500_16], darker: GREY[700] },
    action: { active: GREY[1000], ...COMMON.action },
    link: { primary: GREY[1000], yellowLink: GREY[1000] },
  },
  dark: {
    ...COMMON,
    primary: { ...PRIMARY_YELLOW, contrastText: GREY[1000] },
    primaryButton: {
      ...GREY[900],
      contrastText: GREY[900],
      checkBoxPrimary: GREY[900],
      checkboxFill: GREY[200],
    },
    secondary: { ...SECONDARY, contrastText: GREY[1000] },
    mode: 'dark',
    text: { primary: GREY[0], secondary: GREY[0], disabled: GREY[600], inverse: GREY[900], inverseLight: GREY[800] },
    background: { header: GREY[900], paper: GREY[800], default: GREY[1000], neutral: GREY[500_16], darker: GREY[700] },
    invertedBackground: { paper: GREY[0], default: GREY[200], neutral: GREY[200], darker: GREY[300] },
    action: { active: GREY[0], ...COMMON.action },
    link: { primary: GREY[900], yellowLink: PRIMARY_YELLOW.main },
  },
};

export default palette;
