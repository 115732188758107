// ----------------------------------------------------------------------

export default function Autocomplete(theme) {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        padding: '11px 32px 11px 14px',
        height: '42px',
        root: {
          '& .MuiOutlinedInput-root': {
            backgroundColor: theme.palette.grey[200],
            border: '1px solid rgba(0, 0, 0, 0.3)',
          },
          '& .MuiInputBase-sizeSmall': {
            padding: '11px 32px 11px 14px',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        },
        paper: {
          boxShadow: theme.customShadows.dropdown,
        },
        listbox: {
          padding: theme.spacing(0, 1),
          '& .MuiAutocomplete-option': {
            padding: theme.spacing(1),
            margin: theme.spacing(1, 0),
            borderRadius: theme.shape.borderRadius,
          },
        },
      },
    },
  };
}
