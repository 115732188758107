// ----------------------------------------------------------------------

export default function ControlLabel(theme) {
  return {
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          ...theme.typography.body2,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: theme.spacing(1),
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          // color: theme.palette.text.disabled,
          backgroundColor: theme.palette.mode === 'light' ? 'transparent' : theme.palette.grey[200],
          borderRadius: '8px',
          color: theme.palette.grey[1000_90],
          padding: '5px 10px',
          top: '-8px !important',
          '&.Mui-focused': {
            color: theme.palette.grey[1000_90],
          },
        },
      },
    },
  };
}
