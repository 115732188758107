import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { styled } from '@mui/system';
import { Box, Link, Checkbox, Collapse, FormControlLabel, FormGroup } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CustomButton from '../custom/Buttons/CustomButton';
import css from './CookieBanner.module.css';
import useLocales from '../../hooks/useLocales';
import { GAEvent /* , gtag */ } from '../GoogleAnalytics';

const Banner = styled(Box)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  color: `${theme.palette.primaryButton.main} !important`,
}));

const BannerBackground = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default,
}));

function CookieBanner() {
  const { translate } = useLocales();
  const [cookies, setCookie] = useCookies();
  const theme = useTheme();
  const [bannerClass, setBannerClass] = useState(css.banner);
  const [showBanner, setShowBanner] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [marketing, setMarketing] = useState(false);
  const [statistic, setStatistic] = useState(false);

  useEffect(() => {
    const disabledCookies =
      (localStorage.getItem('cookies') === 'false' && cookies.cookies === undefined) || localStorage.getItem('cookies');
    if (disabledCookies) setBannerClass(css.none);
    else setShowBanner(true);
  }, [cookies.cookies]);

  const changeCookies = (val) => {
    setBannerClass(`${css.banner} ${css.hide}`);

    if (val) {
      setCookie('cookies', val);
      localStorage.setItem('cookies', val);
    }

    setTimeout(() => {
      // const data = {
      //   ad_storage: isValueTrue || marketing ? 'granted' : 'denied', // Marketing
      //   ad_user_data: isValueTrue || marketing ? 'granted' : 'denied', // Marketing
      //   ad_personalization: isValueTrue || marketing ? 'granted' : 'denied', // Marketing
      //   analytics_storage: isValueTrue || statistic ? 'granted' : 'denied', // Štatistické

      //   functionality_storage: 'granted', // Necessary
      //   personalization_storage: 'granted', // Necessary
      //   // personalization_storage: statistic || isValueTrue ? 'granted' : 'denied', // Štatistické
      //   security_storage: 'granted', // Necessary
      // };
      // gtag('consent', 'update', data);
      // if (isValueChange) {
      //   if (marketing) setCookie('cmplz_cookies_marketing', 'granted');
      //   if (statistic) setCookie('cmplz_cookies_statistics', 'granted');
      //   setCookie('cmplz_cookies_functional', 'granted');
      //   setCookie('cookies', 'true');
      // }
      // if (isValueTrue) {
      //   setCookie('cmplz_cookies_marketing', 'granted');
      //   setCookie('cmplz_cookies_functional', 'granted');
      //   setCookie('cmplz_cookies_statistics', 'granted');
      //   setCookie('cookies', val);
      // }
      // localStorage.setItem('cookies', val);
      setBannerClass(css.none);
    }, 480);
  };

  const options = [
    {
      label: 'necesary',
      handleFunc: () => null,
      disabled: false,
      defaultChecked: true,
    },
    {
      label: 'statistic',
      handleFunc: (e) => setStatistic(e.target.checked),
      disabled: false,
      defaultChecked: statistic,
    },
    {
      label: 'marketing',
      handleFunc: (e) => setMarketing(e.target.checked),
      disabled: false,
      defaultChecked: marketing,
    },
  ];

  return (
    showBanner && (
      <>
        <Banner className={bannerClass}>
          <BannerBackground className={css.background} />
          <h3>{translate('cookie.title')}</h3>
          <p style={{ marginTop: '8px' }}>{translate('cookie.text')}</p>
          <Link
            href="https://policies.google.com/technologies/cookies"
            target="_blank"
            rel="noopener"
            style={{
              color: theme.palette.mode === 'light' ? '#000' : '#FFC425',
              textDecoration: 'underline',
              '&:hover': {
                textDecoration: 'underline !important',
              },
            }}
          >
            {translate('cookie.link')}
          </Link>
          {showOptions && (
            <Collapse in={showOptions} sx={{ mt: 1 }}>
              {options.map((option) => (
                <FormGroup key={option.label}>
                  <FormControlLabel
                    label={translate(`cookie.${option.label}`)}
                    control={
                      <StyledCheckbox
                        disabled={option.disabled}
                        checked={option.defaultChecked}
                        size="small"
                        onChange={option.handleFunc}
                      />
                    }
                  />
                </FormGroup>
              ))}
            </Collapse>
          )}
          <div className={css.actions} style={{ mt: 2 }}>
            <CustomButton
              text={!showOptions ? translate('cookie.options') : translate('cookie.accept_options')}
              secondary
              style={{ width: { xs: '100%', sm: '220px' }, mr: 2 }}
              onClick={() => {
                if (showOptions) {
                  changeCookies(true);
                  GAEvent('event', 'ga4.pirjate cookies', {
                    event_category: 'ga4.prijate cookies',
                    event_label: 'ga4.prijate cookie',
                    event_value: `Necessary ${marketing ? 'marketing' : ''}, ${statistic ? 'statistic' : ''}`,
                  });
                } else {
                  setShowOptions(!showOptions);
                  setStatistic(true);
                }
              }}
            />
            <CustomButton
              text={translate('cookie.deny')}
              secondary
              onClick={() => changeCookies('false')}
              style={{ width: { xs: '100%', sm: '220px' }, mr: 2 }}
            />
            <CustomButton
              text={translate('cookie.allow')}
              primary
              onClick={() => changeCookies('true')}
              style={{ width: { xs: '100%', sm: '220px' }, mr: 2 }}
            />
          </div>
        </Banner>
      </>
    )
  );
}

export default CookieBanner;
