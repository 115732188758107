import { useExitIntent } from 'use-exit-intent';
/* eslint-disable no-useless-escape */
import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { Box, Grid, Button, FormGroup, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { HashLink } from 'react-router-hash-link';
import useLocales from '../../../hooks/useLocales';
import { dispatch } from '../../../redux/store';
import { postSubscribe } from '../../../redux/slices/mail';
import CustomModal from './CustomModal';
import { snackActions } from '../../../utils/snackbar';
import useResponsive from '../../../hooks/useResponsive';

// NewsletterModal.propTypes = {
//   open: PropTypes.bool,
//   onClose: PropTypes.func,
// };

export default function NewsletterModal() {
  const { registerHandler, unsubscribe, updateSettings } = useExitIntent({
    cookie: {
      daysToExpire: 14,
      key: 'use-exit-intent',
    },
    desktop: {
      triggerOnIdle: false,
      useBeforeUnload: false,
      triggerOnMouseLeave: true,
      delayInSecondsToTrigger: 1,
    },
    mobile: {
      triggerOnIdle: true,
      delayInSecondsToTrigger: 1,
    },
  });
  const [modalOpen, setModalOpen] = useState(false);
  const { translate } = useLocales();
  const [value, setValue] = useState('');

  const regex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSubmit = () => {
    if (!regex.test(value)) {
      snackActions.error(translate('subscribe.validEmail'));
    } else {
      dispatch(postSubscribe(value))
        .then((resp) => {
          setModalOpen(false);
          handleUpdateSettings();
          handleUnsubscription();
          if (!resp.data.alreadySubscribed) {
            snackActions.success(translate('subscribe.subcribeSuccess'));
          } else {
            snackActions.success(translate('subscribe.subscribeAlready'));
          }
        })
        .catch(() => {});
    }
  };

  registerHandler({
    id: 'openModal',
    handler: () => {
      setModalOpen(true);
    },
  });

  function handleUnsubscription() {
    unsubscribe();
  }

  function handleUpdateSettings() {
    updateSettings({
      cookie: {
        key: 'exit-intent',
        daysToExpire: 30,
      },
    });
  }
  const theme = useTheme();
  const isSmallScreen = useResponsive('down', 'sm');

  function closeModal() {
    handleUpdateSettings();
    handleUnsubscription();
    setModalOpen(false);
  }

  const pathConditions = window.location.pathname.includes('en/')
    ? `/en${translate('urls.conditions')}`
    : translate('urls.conditions');

  return (
    <CustomModal open={modalOpen} onClose={() => closeModal()}>
      <Box sx={{ pt: 2, pb: 2 }}>
        <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
          <Grid xs={8} item sx={{ position: 'relative' }}>
            <Typography variant="h4" sx={{ whiteSpace: 'break-spaces', fontWeight: 'normal' }}>
              {translate('footer.Popupnewsletter1')}
            </Typography>
            <Typography variant="h6" sx={{ whiteSpace: 'break-spaces', fontWeight: 'normal' }}>
              {translate('footer.Popupnewsletter2')}
            </Typography>
            <FormGroup row sx={{ mt: 2 }}>
              <TextField
                placeholder={translate('footer.writeEmail')}
                onChange={handleInput}
                variant="filled"
                type="email"
                size="small"
                fullWidth
                sx={{
                  input: { color: 'black', '&::placeholder': { color: '#888888 !important' } },
                  maxWidth: { xs: '100%', md: '100%', sm: '100%' },
                  margin: { xs: '0 auto', md: '0' },
                }}
                InputProps={{
                  disableUnderline: true,
                  inputProps: {
                    style: { marginTop: '-14px', marginBottom: '4px', marginLeft: '12px' },
                  },
                  endAdornment: isSmallScreen ? null : (
                    <Button variant="contained" onClick={handleSubmit} sx={{ height: '100%', marginRight: '-13px' }}>
                      {translate('footer.login')}
                    </Button>
                  ),
                  style: { color: '#FFFFFF' },
                }}
              />
              {isSmallScreen && (
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  sx={{
                    margin: '0 auto !important',
                    marginTop: '4px !important',
                    width: '100% !important',
                  }}
                >
                  {translate('footer.login')}
                </Button>
              )}
            </FormGroup>
            <Typography variant="subtitle2" sx={{ pr: { md: 5 }, mt: 1, fontWeight: 'normal' }}>
              {translate('footer.confirm1')}
              {/* <WhiteLink sx={{ textDecoration: "underline" }}> */}
              {/* {translate('footer.confirm2')} */}
              <HashLink
                style={{
                  color: theme.palette.mode === 'light' ? '#000' : '#FFC425',
                  textDecoration: 'underline',
                  '&:hover': {
                    textDecoration: 'underline !important',
                  },
                }}
                to={`${pathConditions}#dataProtection`}
              >
                {translate('footer.confirm2')}
              </HashLink>
              {/* </WhiteLink> */}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </CustomModal>
  );
}
