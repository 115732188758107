import { useState, useEffect, useContext } from 'react';
import { SettingsContext } from '../contexts/SettingsContext';

// ----------------------------------------------------------------------

export default function useLocalStorage(key, defaultValue) {
  const defaultThemeValues = useContext(SettingsContext);

  const [value, setValue] = useState(() => {
    const storedValue = localStorage.getItem(key);
    return storedValue === null ? defaultValue : JSON.parse(storedValue);
  });

  useEffect(() => {
    if (localStorage.getItem('settings') === null) {
      localStorage.setItem('settings', JSON.stringify(defaultThemeValues));
    }

    const listener = (e) => {
      if (e.storageArea === localStorage && e.key === key) {
        setValue(JSON.parse(e.newValue));
      }
    };
    const changeValue = () => {
      setTimeout(() => {
        setValue(JSON.parse(localStorage.getItem(key)));
      }, 200);
    };
    window.addEventListener('storage', listener);
    window.addEventListener('change', changeValue);

    return () => {
      window.removeEventListener('storage', listener);
      window.removeEventListener('change', changeValue);
    };
  }, [key, defaultValue, defaultThemeValues]);

  const setValueInLocalStorage = (newValue) => {
    setValue((currentValue) => {
      const result = typeof newValue === 'function' ? newValue(currentValue) : newValue;
      localStorage.setItem(key, JSON.stringify(result));
      window.dispatchEvent(new Event('change'));
      return result;
    });
  };

  return [value, setValueInLocalStorage];
}
