import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

import { NavLink as RouterLink, useLocation } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { List, Drawer, ListItemText, ListItemButton, Divider, ListItemIcon, Box } from '@mui/material';
// config
import { NAVBAR } from '../../config';
// components
import Iconify from '../Iconify';
import Scrollbar from '../Scrollbar';
import { IconButtonAnimate } from '../animate';
import Logo from '../Logo';
import useLocales from '../../hooks/useLocales';
import ModeSettingsSwitch from './ModeSettingsSwitch';
import LanguageSelect from '../custom/LanguageSelect/LanguageSelect';
import { useSelector } from '../../redux/store';

// ----------------------------------------------------------------------

const ListItemStyle = styled(ListItemButton)(({ theme }) => ({
  ...theme.typography.body2,
  textTransform: 'capitalize',
  height: NAVBAR.DASHBOARD_ITEM_ROOT_HEIGHT,
  color: theme.palette.text.secondary,
}));

// ----------------------------------------------------------------------

MenuMobile.propTypes = {
  isOffset: PropTypes.bool,
  navConfig: PropTypes.array,
  topNavConfig: PropTypes.array,
};

export default function MenuMobile({ isOffset, navConfig, topNavConfig }) {
  const { pathname } = useLocation();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  useEffect(() => {
    setDrawerOpen(false);
  }, [pathname]);

  return (
    <>
      <IconButtonAnimate
        aria-label="Burger menu"
        onClick={handleDrawerOpen}
        sx={{
          ml: 1,
          ...(isOffset && { color: 'text.primary' }),
        }}
      >
        <Iconify icon={'eva:menu-2-fill'} />
      </IconButtonAnimate>

      <Drawer
        open={drawerOpen}
        onClose={handleDrawerClose}
        ModalProps={{ keepMounted: true }}
        PaperProps={{ sx: { pb: 5, width: 380 } }}
      >
        <Scrollbar>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Logo sx={{ mx: 2.5, my: 3 }} />
            <Iconify
              icon="mdi:close"
              sx={{ mt: 2, mr: 2 }}
              width="24px"
              height="24px"
              onClick={() => handleDrawerClose()}
            />
          </Box>
          <List disablePadding sx={{ mt: 3 }}>
            {navConfig.map((link) =>
              link.display ? <MenuItem key={link.title} item={link} icon={link.icon} iconW={link.iconW} /> : null
            )}
          </List>
          <Divider />
          <List disablePadding sx={{ mt: 2 }}>
            {topNavConfig.map((link) => <MenuItem key={link.title} item={link} />).reverse()}
          </List>
          <Divider />
          <SettingsBox sx={{ mt: 2 }}>
            <LanguageSelect />
            <ModeSettingsSwitch inMenu />
          </SettingsBox>
        </Scrollbar>
      </Drawer>
    </>
  );
}

const SettingsBox = styled('div')(() => ({
  display: 'block',
  paddingLeft: '26px',
  // textAlign: 'center',
}));

MenuItem.propTypes = {
  item: PropTypes.shape({
    children: PropTypes.array,
    icon: PropTypes.any,
    iconW: PropTypes.any,
    path: PropTypes.string,
    pathT: PropTypes.string,
    title: PropTypes.string,
  }),
};
const ICON_SIZE = {
  width: 48,
  height: 48,
};

function MenuItem({ item }) {
  const { translate } = useLocales();
  const theme = useTheme();
  const { favourites } = useSelector((state) => state.favourites);

  const { title, path, pathT, icon, iconW } = item;

  return (
    <ListItemStyle
      to={window.location.pathname.includes('en/') ? `en${translate(pathT)}` : translate(pathT)}
      component={RouterLink}
      end={path === '/'}
      sx={{
        mb: 2,
        '& .MuiListItemIcon-root': {
          mr: title === 'default.favorites' ? '0' : '13px',
        },
        '&.active': {
          color: 'text.primary',
          // boxShadow: theme.palette.mode === 'dark' ? '0px -2px 0px white inset' : '0px -2px 0px black inset',
          fontWeight: '900',
        },
      }}
    >
      <ListItemIcon
        sx={{
          '&.active': {
            color: 'primary.main',
          },
        }}
      >
        {title === 'default.favorites' && favourites?.length > 0 && (
          <Iconify
            icon={'ant-design:heart-filled'}
            color="#D16666"
            sx={{ mr: 1, position: 'relative', verticalAlign: 'bottom' }}
            {...ICON_SIZE}
          />
        )}
        {title === 'default.favorites' && favourites?.length === 0 && (
          <Iconify
            icon={'ant-design:heart-filled'}
            sx={{ mr: 1, position: 'relative', verticalAlign: 'bottom' }}
            {...ICON_SIZE}
          />
        )}
        {title !== 'default.favorites' && theme.palette.mode === 'dark' && iconW}
        {title !== 'default.favorites' && theme.palette.mode === 'light' && icon}
      </ListItemIcon>
      <ListItemText
        disableTypography
        primary={translate(title)}
        sx={{
          textTransform: 'uppercase',
          '&.active': {
            color: 'text.primary',
            textDecorationLine: 'underline',
            textUnderlinePosition: 'under',
            fontWeight: '900',
          },
        }}
      />
      {/* title !== 'default.favorites' && icon */}
      {/* {(title === 'default.favorites' && favorites.length > 0) && <span className='favorites favMobile'>{favorites.length}</span>} */}
    </ListItemStyle>
  );
}

/* =============================== MENU ITEM WITH SUBMENU
MenuMobileItem.propTypes = {
  isOpen: PropTypes.bool,
  item: PropTypes.shape({
    children: PropTypes.array,
    icon: PropTypes.any,
    path: PropTypes.string,
    title: PropTypes.string,
  }),
  onOpen: PropTypes.func,
};

function MenuMobileItem({ item, isOpen, onOpen }) {
  const { title, path, icon, children } = item;

  if (children) {
    return (
      <>
        <ListItemStyle onClick={onOpen}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText disableTypography primary={title} />
          <Iconify
            icon={isOpen ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>

        <Collapse in={isOpen} unmountOnExit>
          <Box sx={{ display: 'flex', flexDirection: 'column-reverse' }}>
            <NavSectionVertical
              navConfig={children}
              sx={{
                '& .MuiList-root:last-of-type .MuiListItemButton-root': {
                  height: 200,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  bgcolor: 'background.neutral',
                  backgroundRepeat: 'no-repeat',
                  backgroundImage: 'url(/assets/illustrations/illustration_dashboard.png)',
                  '& > *:not(.MuiTouchRipple-root)': { display: 'none' },
                },
              }}
            />
          </Box>
        </Collapse>
      </>
    );
  }

  return (
    <ListItemStyle
      to={path}
      component={RouterLink}
      end={path === '/'}
      sx={{
        '&.active': {
          color: 'primary.main',
          fontWeight: 'fontWeightMedium',
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
        },
      }}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText disableTypography primary={title} />
    </ListItemStyle>
  );
}
*/
