import { createSlice } from '@reduxjs/toolkit';
import qs from 'qs';

// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  media: { totalCount: '', results: [] },
  prev: {},
  next: {},
};

const slice = createSlice({
  name: 'media',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MEDIA
    getMediaSuccess(state, action) {
      state.isLoading = false;
      if (action.payload.loadMore && !action.payload.sortAttribute) {
        state.media.totalCount = action.payload.totalCount;
        state.media.hasMoreResults = action.payload.hasMoreResults;
        state.media.results = [...state.media.results, ...action.payload.results];
      } else {
        delete action.payload.sortAttribute;
        delete action.payload.loadMore;
        state.media = action.payload;
      }
    },
    getNextSuccess(state, action) {
      state.isLoading = false;
      state.next = action.payload;
    },
    getPrevSuccess(state, action) {
      state.isLoading = false;
      state.prev = action.payload;
    },
    getVideoCountSuccess(state, action) {
      state.isLoading = false;
      state.media.totalCount = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getMediaSuccess } = slice.actions;

// ----------------------------------------------------------------------

export function getMedia(searchText, filter, page, perPage /* , sort */) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/videolibraries', {
        params: {
          size: perPage,
          page: page - 1,
          sortAttribute: undefined,
          sortDirection: undefined,
        },
      });
      dispatch(slice.actions.getMediaSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDetailNext(key) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/videolibrary/${key}`);
      dispatch(slice.actions.getNextSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getDetailPrev(key) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/videolibrary/${key}`);
      dispatch(slice.actions.getPrevSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getDetailNextEDU(key) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/edumaterial/${key}`);
      dispatch(slice.actions.getNextSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getDetailPrevEDU(key) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/edumaterial/${key}`);
      dispatch(slice.actions.getPrevSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getVideoListForInput(searchText, size) {
  return async () => {
    try {
      const response = await axios.get(`/videolibrary/search`, {
        params: {
          sortAttribute: 'rank',
          sortDirection: 1,
          size,
          page: 0,
          searchText: searchText?.replaceAll("\"", ""),
          searchByKeywords: !searchText?.includes("\""),
        },
        paramsSerializer: (params) => qs.stringify(params),
      });
      return response;
    } catch (error) {
      return dispatch(slice.actions.hasError(error));
    }
  };
}

export function getVideoSearch({
  searchText,
  size,
  sortAttribute = 'created',
  sortDirection = '1',
  page,
  categoryKeys,
  tagKeys,
  loadMore,
  signal,
  // searchByKeywords,
  locale,
}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/videolibrary/search`, {
        params: {
          sortAttribute,
          sortDirection,
          size,
          page: page - 1,
          categoryKeys,
          tagKeys,
          searchText: searchText?.replaceAll('"', ''),
          searchByKeywords: !searchText?.includes('"'),
          locale,
        },
        signal,
        paramsSerializer: (params) => qs.stringify(params),
      });
      dispatch(slice.actions.getMediaSuccess({ ...response.data, loadMore, sortAttribute }));
    } catch (error) {
      if (!signal.aborted) {
        dispatch(slice.actions.hasError(error));
      }
    }
  };
}

export function getVideoCount({ searchText, categoryKeys, tagKeys }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/videolibrary/search/totalCount', {
        params: {
          categoryKeys,
          tagKeys,
          searchText: searchText?.replaceAll("\"", ""),
          searchByKeywords: !searchText?.includes("\""),
        },
        paramsSerializer: (params) => qs.stringify(params),
      });
      dispatch(slice.actions.getVideoCountSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
