import { useEffect, useMemo } from 'react';
import { matchRoutes, useLocation, useNavigate } from 'react-router-dom';
import i18next from 'i18next';
import useLocales from './hooks/useLocales';
import routesConfig, { topRoutesConfig } from './routes/config';

const AlternateLinks = () => {
  const { pathname, search } = useLocation();
  const location = useLocation();
  const navigate = useNavigate();

  const { allLangs, currentLang } = useLocales();

  const ignoredLang = useMemo(() => ['sk'], []); // musia sa definovat manualne
  const languages = useMemo(
    () =>
      allLangs
        .filter((lang) => !ignoredLang.includes(lang.value))
        .map((lang) => ({ ...lang, t: i18next.getFixedT(lang.value) })),
    [allLangs, ignoredLang]
  );
  const t = i18next.getFixedT('sk');

  const routes = useMemo(
    () =>
      routesConfig.flatMap((route) => [
        {
          path: route.pathT ? t(route.pathT) : route.path,
          element: route.element,
        },
        ...languages.map((lang) => ({
          path: `/${lang.value}${route.pathT ? lang.t(route.pathT) : route.path}`, // ${lang.t(route.path)}
          element: route.element,
        })),
      ]),
    [languages, t]
  );

  const topRoutes = useMemo(
    () =>
      topRoutesConfig.flatMap((route) => [
        {
          path: route.pathT ? t(route.pathT) : route.path,
          element: route.element,
        },
        ...languages.map((lang) => ({
          path: `/${lang.value}${route.pathT ? lang.t(route.pathT) : route.path}`, // ${lang.t(route.path)}
          element: route.element,
        })),
      ]),
    [languages, t]
  );

  const allRoutes = useMemo(() => [...routes, ...topRoutes], [routes, topRoutes]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const [{ route }] = matchRoutes(routes, location); // nesmie sa premenovat

    let actualIndex = allRoutes.findIndex((troute) => troute.path === route?.path);
    const actualRoute = allRoutes[actualIndex];
    actualIndex =
      Math.floor(actualIndex / (ignoredLang.length + languages.length)) * (ignoredLang.length + languages.length);

    const ignoredIdx = ignoredLang.findIndex((ilang) => ilang !== currentLang.value);
    const langIdx = languages.findIndex((lang) => lang.value !== currentLang.value);

    const newRoute =
      ignoredIdx >= 0 ? routes[actualIndex + ignoredIdx] : routes[actualIndex + ignoredLang.length + langIdx];

    /* let path = pathname;
    languages.forEach((lang) => {
      // eslint-disable-next-line
      path = path.replace(`/${lang.value}/`, '/');
    });
    if (ignoredIdx < 0) {
      path = `/${currentLang.value}${path}`;
    } */
    if (actualRoute.path !== newRoute.path) {
      const doubleIdx = actualRoute.path.indexOf(':');
      let params = '';
      let newPath = newRoute.path;
      if (doubleIdx >= 0) {
        params = pathname.substring(doubleIdx);
        const newDoubleIdx = newPath.indexOf(':');
        newPath = newPath.substring(0, newDoubleIdx) + params;
      }

      const alternateLinks = [
        {
          lang: window.location.pathname.startsWith('/en') ? 'sk' : 'en',
          url: `${window.location.origin}${newPath}`,
        },
        {
          lang: window.location.pathname.startsWith('/en') ? 'en' : 'sk',
          url: `${window.location.href.split('?')[0]}`,
        },
      ];

      alternateLinks.forEach((link) => {
        if (link.lang === 'sk') {
          const linkElement = document.createElement('link');
          linkElement.rel = 'canonical';
          linkElement.hreflang = 'x-default';
          linkElement.href = link.url;
          document.head.appendChild(linkElement);
        } else {
          const linkElement = document.createElement('link');
          linkElement.rel = 'alternate';
          linkElement.hreflang = link.lang;
          linkElement.href = link.url;
          document.head.appendChild(linkElement);
        }
      });

      return () => {
        alternateLinks.forEach((link) => {
          const existingLink = document.querySelector(`link[href="${link.url}"]`);
          if (existingLink) {
            document.head.removeChild(existingLink);
          }
        });
      };
    }
  }, [allRoutes, currentLang, languages, ignoredLang, pathname, search, navigate, location, routes]);

  useEffect(() => {}, []);
  return null;
};

export default AlternateLinks;
