/* eslint-disable no-useless-escape */
import { Button, FormGroup, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useExitIntent } from 'use-exit-intent';
import { postSubscribe } from '../../redux/slices/mail';
import useLocales from '../../hooks/useLocales';
import { dispatch } from '../../redux/store';
import { snackActions } from '../../utils/snackbar';

export default function FooterNewsletter() {
  const { translate } = useLocales();
  const { unsubscribe, updateSettings } = useExitIntent({
    cookie: {
      daysToExpire: 14,
      key: 'use-exit-intent',
    },
    desktop: {
      triggerOnIdle: false,
      useBeforeUnload: false,
      triggerOnMouseLeave: true,
      delayInSecondsToTrigger: 1,
    },
    mobile: {
      triggerOnIdle: true,
      delayInSecondsToTrigger: 1,
    },
  });
  const [value, setValue] = useState('');

  const regex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  /*  const handleSubmitt = async () => {
    if (!regex.test(value)) {
      setErrorMsg('Email is not valid');
      setAlertMsg('Email musí byť v správnom tvare!');
      setOpen('error');
      setSeverity('error');
    } else {
      try {
        //   await axios.post(`some api`);
        axios.post("https://api2.ecomailapp.com/lists/4/subscribe",
        `subscriber_data[email] = ${value}`
        , {
          headers: {
            'key': "TODO_KEY",
            'content-type': 'text/json'
          }
        })
        .then((resp) => {
          console.log(resp);
        })
        .catch(() => {});
        setErrorMsg('');
        setOpen('success');
        setSeverity('success');
        setAlertMsg('Úspešne ste sa prihlásili na odber noviniek');
        setValue('');

      }  catch (error) {
        console.log(error);
      }
    }
  };  */
  function handleUnsubscription() {
    unsubscribe();
  }
  function handleUpdateSettings() {
    updateSettings({
      cookie: {
        key: 'exit-intent',
        daysToExpire: 30,
      },
    });
  }
  const handleSubmit = () => {
    if (!regex.test(value)) {
      snackActions.error(translate('subscribe.validEmail'));
    } else {
      dispatch(postSubscribe(value))
        .then((resp) => {
          handleUpdateSettings();
          handleUnsubscription();
          if (!resp.data.alreadySubscribed) {
            snackActions.success(translate('subscribe.subcribeSuccess'));
          } else {
            snackActions.success(translate('subscribe.subscribeAlready'));
          }
          window.location.refresh();
        })
        .catch(() => {});
    }
  };

  // const handleClose = (event, reason) => {
  //   if (reason === 'clickaway') {
  //     return;
  //   }

  //   setOpen('');
  // };

  return (
    <FormGroup row sx={{ mt: 2 }}>
      <TextField
        placeholder={translate('footer.writeEmail')}
        onChange={handleInput}
        variant="filled"
        type="email"
        //    error={!!errorMsg}
        size="small"
        fullWidth
        sx={{
          input: { color: 'black', '&::placeholder': { color: '#888888 !important' } },
          maxWidth: { xs: '100%', md: '100%', sm: '85%' },
          margin: { xs: '0 auto', md: '0' },
        }}
        InputProps={{
          disableUnderline: true,
          inputProps: {
            style: { marginTop: '-14px', marginBottom: '4px', marginLeft: '12px' },
          },
          endAdornment: (
            <Button variant="contained" onClick={handleSubmit} sx={{ height: '100%', marginRight: '-13px' }}>
              {translate('footer.login')}
            </Button>
          ),
          style: { color: '#FFFFFF' },
        }}
      />
    </FormGroup>
  );
}
