import PropTypes from 'prop-types';
// import { Link as RouterLink } from 'react-router-dom';
// @mui
import { NavLink as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box, Link } from '@mui/material';
import logoDark from '../assets/images/logo-dark.png';
import logoLight from '../assets/images/logo-light.png';
import logoBigDark from '../assets/images/logo-big-dark.png';
import logoBigLight from '../assets/images/logo-big-light.png';
import logoBigDarkEN from '../assets/images/logo-big-dark-en.png';
import logoBigLightEN from '../assets/images/logo-big-light-en.png';
import useLocales from '../hooks/useLocales';

// ----------------------------------------------------------------------

Logo.propTypes = {
  big: PropTypes.bool,
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx, big = false }) {
  const theme = useTheme();
  // const { pathname } = useLocation();
  const { currentLang } = useLocales();
  const dark = big ? logoBigDark : logoDark;
  const light = big ? logoBigLight : logoLight;
  const darkEN = big ? logoBigDarkEN : logoDark;
  const lightEN = big ? logoBigLightEN : logoLight;

  const logo = (
    <Box
      component="img"
      alt="logo"
      src={theme.palette.mode === 'dark' ? light : dark}
      sx={{ marginLeft: 2, maxWidth: 'calc(100% - 16px)', maxHeight: 40, cursor: 'pointer', ...sx }}
    />
  );

  const logoEN = (
    <Box
      component="img"
      alt="logo"
      src={theme.palette.mode === 'dark' ? lightEN : darkEN}
      sx={{ marginLeft: 2, maxWidth: 'calc(100% - 16px)', maxHeight: 40, cursor: 'pointer', ...sx }}
    />
  );

  if (disabledLink) return <>{logo}</>;

  // if (pathname === '/domov' || pathname === '/') {
  //   return (
  //     <Link
  //       href="/domov"
  //       to="/domov"
  //       onClick={(e) => {
  //         e.preventDefault();
  //         window.scrollTo({ top: 0, left: 0 });
  //       }}
  //     >
  //       {currentLang.getValue === 'skSK' ? logo : logoEN}
  //     </Link>
  //   );
  // }
  return (
    <Link
      to={window.location.pathname.startsWith('/en') ? '/en/home' : '/domov'}
      component={RouterLink}
      sx={{ typography: 'body2', display: 'inline-flex', fontSize: 13 }}
    >
      {currentLang.getValue === 'skSK' ? logo : logoEN}
    </Link>
  );
}
