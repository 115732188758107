import { createSlice } from '@reduxjs/toolkit';
import qs from 'qs';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  linked: [],
  articles: { totalCount: '', results: [] },
  mostReaded: {},
  currentArticle: {},
  randomArticle: {},
  articlesCO: [],
  relatedCo: [],
  prev: null,
  next: null,
};

const slice = createSlice({
  name: 'articles',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ARTICLES
    getArticlesSuccess(state, action) {
      state.isLoading = false;
      if (action.payload.loadMore && !action.payload.sortAttribute) {
        state.articles.totalCount = action.payload.totalCount;
        state.articles.hasMoreResults = action.payload.hasMoreResults;
        state.articles.results = [...state.articles.results, ...action.payload.results];
        state.currentArticle = {};
      } else {
        delete action.payload.sortAttribute;
        delete action.payload.loadMore;
        state.articles = action.payload;
        state.currentArticle = {};
      }
    },

    getArticlesCountSuccess(state, action) {
      state.articles.totalCount = action.payload;
    },

    getRandomArticleSuccess(state, action) {
      state.isLoading = false;
      state.randomArticle = action.payload;
    },

    getRelatedCoSuccess(state, action) {
      state.isLoading = false;
      state.relatedCo = action.payload;
    },

    resetRelatedCoSuccess(state) {
      state.isLoading = false;
      state.relatedCo = [];
    },

    getMostReadedArticlesSuccess(state, action) {
      state.relatedCo = [];
      state.isLoading = false;
      state.mostReaded = action.payload;
    },

    getCurrentArticleSuccess(state, action) {
      state.isLoading = false;
      state.relatedCo = [];
      state.linked = [];
      state.currentArticle = action.payload;
    },

    getArticleAdjacentSuccess(state, action) {
      state.prev = action.payload.previous;
      state.next = action.payload.next;
    },
    getArticlesCoSuccess(state, action) {
      state.isLoading = false;
      state.articlesCO = [...state.articlesCO, action.payload];
    },
    getLinkedSuccess(state, action) {
      // const index = state.linked.findIndex((item) => item.key === action.payload.key);
      // if (index === -1) {
      const temp = state.linked.filter((item) => item.key !== action.payload.key);
      state.linked = [...temp, action.payload];
      //  state.linked = [action.payload];
      // }
      state.isLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getArticlesSuccess, getLinkedSuccess, startLoading, stopLoading } = slice.actions;

// ----------------------------------------------------------------------

export function getArticles(searchText, filter, page, perPage /* , sort */) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/articles', {
        params: {
          size: perPage,
          page: page - 1,
          sortAttribute: undefined,
          sortDirection: undefined,
        },
      });
      dispatch(slice.actions.getArticlesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getArticleListForInput(searchText, size) {
  return async () => {
    try {
      const response = await axios.get(`/article/search`, {
        params: {
          sortAttribute: 'rank',
          sortDirection: 1,
          size,
          page: 0,
          searchText: searchText?.replaceAll("\"", ""),
          searchByKeywords: !searchText?.includes("\""),
          articleTypes: [0, 1],
        },
        paramsSerializer: (params) => qs.stringify(params),
      });
      return response;
    } catch (error) {
      return dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMapListForInput(searchText, size) {
  return async () => {
    try {
      const response = await axios.get(`/article/search`, {
        params: {
          sortAttribute: 'rank',
          sortDirection: 1,
          size,
          page: 0,
          searchText: searchText?.replaceAll("\"", ""),
          searchByKeywords: !searchText?.includes("\""),
          articleTypes: [1, 2],
          MapSearch: true
        },
        paramsSerializer: (params) => qs.stringify(params),
      });
      return response;
    } catch (error) {
      return dispatch(slice.actions.hasError(error));
    }
  };
}

export function getArticlesSearch(
  {
    searchText,
    size,
    sortAttribute = 'created',
    sortDirection,
    page,
    categoryKeys,
    tagKeys,
    articleTypes,
    loadMore,
    WithComponent,
    signal,
    // searchByKeywords,
    locale,
  } /* , sort */
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/article/search`, {
        params: {
          articleTypes,
          sortAttribute: sortAttribute || 'created',
          sortDirection: sortDirection || 1,
          size,
          page: page - 1,
          categoryKeys,
          tagKeys,
          searchText: searchText?.replaceAll("\"", ""),
          searchByKeywords: !searchText?.includes("\""),
          WithComponent,
          locale,
        },
        signal,
        paramsSerializer: (params) => qs.stringify(params),
      });
      dispatch(slice.actions.getArticlesSuccess({ ...response.data, loadMore, sortAttribute }));
    } catch (error) {
      if (!signal.aborted) {
        dispatch(slice.actions.hasError(error));
      }
    }
  };
}

export function getArticlesCount({ searchText, categoryKeys, tagKeys, articleTypes }) {
  return async () => {
    try {
      const response = await axios.get('/article/search/totalCount', {
        params: {
          articleTypes,
          categoryKeys,
          tagKeys,
          searchText: searchText?.replaceAll("\"", ""),
          searchByKeywords: !searchText?.includes("\""),
        },
        paramsSerializer: (params) => qs.stringify(params),
      });
      dispatch(slice.actions.getArticlesCountSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMostReadedArticles(size = 3, locale = 'skSK') {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/articles/mostread`, {
        params: { size, page: 0, locale, articleTypes: [0, 1] },
        paramsSerializer: (params) => qs.stringify(params),
      });
      dispatch(slice.actions.getMostReadedArticlesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getArticle(key, locale) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/article/${key}`, {
        params: {
          locale,
        },
        paramsSerializer: (params) => qs.stringify(params),
      });
      dispatch(slice.actions.getCurrentArticleSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRandomArticle(locale = 'skSK') {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/article/random`, { params: { locale } });
      dispatch(slice.actions.getRandomArticleSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getArticlesCO({ key, locale }) {
  // eslint-disable-next-line consistent-return
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/culturalobject/${key}`, { params: { locale } });
      dispatch(slice.actions.getArticlesCoSuccess(response.data));
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function resetRelatedCo() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.resetRelatedCoSuccess());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRelatedCo(key) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/folder/${key}`);
      dispatch(slice.actions.getRelatedCoSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getArticleAdjacent({
  articleTypes,
  index,
  searchText,
  sortAttribute,
  sortDirection,
  categoryKeys,
  tagKeys,
  // searchByKeywords,
  MapSearch
}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/article/search/adjacent`, {
        params: {
          index,
          articleTypes,
          sortAttribute,
          sortDirection,
          categoryKeys,
          tagKeys,
          searchText: searchText?.replaceAll("\"", ""),
          searchByKeywords: !searchText?.includes("\""),
          MapSearch
        },
        paramsSerializer: (params) => qs.stringify(params),
      });
      dispatch(slice.actions.getArticleAdjacentSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getArticlePreview(key, { locale }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/article/${key}/preview`, {
        params: {
          locale,
        },
        paramsSerializer: (params) => qs.stringify(params),
      });
      dispatch(slice.actions.getLinkedSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return null;
    }
  };
}

// ----------------------------------------------------------------------
