import { createSlice } from '@reduxjs/toolkit';
import qs from 'qs';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  districts: [],
  cities: [],
  years: [],
  sheets: {
    totalCount: '',
    results: [],
  },
  sheetCategories: {},
};

const slice = createSlice({
  name: 'sheets',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getSheetsSuccess(state, action) {
      state.isLoading = false;
      if (action.payload.loadMore && !action.payload.sortAttribute) {
        state.sheets.totalCount = action.payload.totalCount;
        state.sheets.hasMoreResults = action.payload.hasMoreResults;
        state.sheets.results = [...state.sheets.results, ...action.payload.results];
      } else {
        delete action.payload.sortAttribute;
        delete action.payload.loadMore;
        state.sheets = action.payload;
      }
    },
    getCountSuccess(state, action) {
      state.isLoading = false;
      state.sheets.totalCount = action.payload;
    },

    getCategoriesSuccess(state, action) {
      state.mapCategories = action.payload;
      state.isLoading = false;
    },

    getDistrictSuccess(state, action) {
      state.districts = action.payload.results;
      state.isLoading = false;
    },

    getCitiesSuccess(state, action) {
      state.cities = action.payload.results;
      state.isLoading = false;
    },

    getYearsSuccess(state, action) {
      state.years = action.payload.results;
      state.isLoading = false;
    },

    clearSheets(state) {
      state.sheets = { totalCount: '', results: [] };
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getArticlesSuccess, clearSheets } = slice.actions;

// ----------------------------------------------------------------------

export function getSheetListForInput(searchText, size) {
  return async () => {
    try {
      const response = await axios.get(`/censussheet/search`, {
        params: {
          sortAttribute: 'rank',
          sortDirection: 1,
          size,
          page: 0,
          searchText: searchText?.replaceAll("\"", ""),
          searchByKeywords: !searchText?.includes("\""),
        },
        paramsSerializer: (params) => qs.stringify(params),
      });
      return response;
    } catch (error) {
      return dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSheetsSearch({
  searchText,
  size,
  sortAttribute = 'created',
  sortDirection,
  page,
  categoryKeys,
  tagKeys,
  loadMore,
  onlyUnBlackened = true,
  signal,
}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/censussheet/search`, {
        params: {
          sortAttribute,
          sortDirection,
          size,
          page: page - 1,
          categoryKeys,
          tagKeys,
          searchText: searchText?.replaceAll("\"", ""),
          searchByKeywords: !searchText?.includes("\""),
          onlyUnBlackened,
        },
        signal,
        paramsSerializer: (params) => qs.stringify(params),
      });
      dispatch(slice.actions.getSheetsSuccess({ ...response.data, loadMore, sortAttribute }));
    } catch (error) {
      if (!signal.aborted) {
        dispatch(slice.actions.hasError(error));
      }
    }
  };
}

export function getSheetsCount({ searchText, categoryKeys, tagKeys }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/censussheets/search/totalCount', {
        params: {
          categoryKeys,
          tagKeys,
          searchText: searchText?.replaceAll("\"", ""),
          searchByKeywords: !searchText?.includes("\""),
        },
        paramsSerializer: (params) => qs.stringify(params),
      });
      dispatch(slice.actions.getCountSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDistricts({ autocompleteText, locale = 'skSK', filter }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/censussheet/filter/districts', {
        params: { autocompleteText, locale, ...filter },
        paramsSerializer: (params) => qs.stringify(params),
      });
      return dispatch(slice.actions.getDistrictSuccess(response.data));
    } catch (error) {
      return dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCities({ autocompleteText, locale = 'skSK', filter }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/censussheet/filter/cities', {
        params: { autocompleteText, locale, ...filter },
        paramsSerializer: (params) => qs.stringify(params),
      });
      return dispatch(slice.actions.getCitiesSuccess(response.data));
    } catch (error) {
      return dispatch(slice.actions.hasError(error));
    }
  };
}

export function getYears({ locale = 'skSK', filter }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/censussheet/filter/years', {
        params: { locale, ...filter },
        paramsSerializer: (params) => qs.stringify(params),
      });
      return dispatch(slice.actions.getYearsSuccess(response.data));
    } catch (error) {
      return dispatch(slice.actions.hasError(error));
    }
  };
}
