import { NavLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Grid, Divider, Container, Typography, Box, Link } from '@mui/material';
// routes
// import { PATH_PAGE } from '../../routes/paths';
// components
import { HashLink } from 'react-router-hash-link';
import CustomFooterIcon from './CustomFooterIcon';
import useLocales from '../../hooks/useLocales';
import FooterNewsletter from './FooterNewsletter';
import NewsletterModal from '../custom/Modals/NewsletterModal';
import europeanaStamp from '../../assets/images/europeana-stamp.png';
// import Logo from '../Logo';
// ----------------------------------------------------------------------

const WhiteLink = styled(NavLink)(() => ({
  color: 'unset',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

const RootStyle = styled('div')(() => ({
  position: 'relative',
  backgroundColor: '#0D0F12',
  color: '#FFFFFF',
}));

export default function CustomFooter() {
  const { translate, currentLang } = useLocales();
  const today = new Date();
  const year = today.getFullYear();
  const pathConditions = window.location.pathname.includes('en/')
    ? `/en${translate('urls.conditions')}`
    : translate('urls.conditions');

  const pathSlovakiana = window.location.pathname.includes('en/')
    ? `/en${translate('urls.slovakiana')}`
    : translate('urls.slovakiana');

  const pathInst = window.location.pathname.includes('en/')
    ? `/en${translate('urls.institutions')}`
    : translate('urls.institutions');

  const pathAccess = window.location.pathname.includes('en/')
    ? `/en${translate('urls.accesibility')}`
    : translate('urls.accesibility');

  return (
    <RootStyle>
      <Container>
        <Grid container sx={{ p: 4 }}>
          <Grid xs={12} md={6} sx={{ pt: { md: 2, xs: 0 }, display: { xs: 'flex', md: 'block' } }} item>
            <Box sx={{ margin: '0 auto', textAlign: { md: 'left', xs: 'center' } }}>
              <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 1 }}>
                {translate('footer.stayInContact')}
              </Typography>
              <CustomFooterIcon icon={'fa:facebook'} link="https://www.facebook.com/slovakiana" />
              <CustomFooterIcon icon={'fa:instagram'} link="https://www.instagram.com/slovakiana.sk/" />
              <CustomFooterIcon icon={'fa:youtube'} link="https://www.youtube.com/channel/UCg12lrChZGI6gseMAogFjww" />
              <CustomFooterIcon icon={'fa:envelope'} link="mailto:slovakiana@nocka.sk?subject=Napíšte nám " />
            </Box>
          </Grid>
          <Grid xs={12} md={6} sx={{ pt: 2, textAlign: { xs: 'center', md: 'left' } }} item>
            {/* <Typography variant="h4" sx={{ fontWeight: 'normal', }}>{translate('footer.newsletter1')}</Typography> */}
            {/* <Typography variant="h4" sx={{ fontWeight: 'normal', whiteSpace: { sm: 'nowrap' } }}>{translate('footer.nwesletter2')}</Typography> */}
            <Typography variant="h4" sx={{ whiteSpace: 'break-spaces', fontWeight: 'normal' }}>
              {/* here here */}
              {translate('footer.newsletter1')} {translate('footer.nwesletter2')}
            </Typography>
            <FooterNewsletter />
            <NewsletterModal />
            <Typography variant="subtitle2" sx={{ pr: { md: 5 }, mt: 1, fontWeight: 'normal' }}>
              {translate('footer.confirm1')}
              {/* <WhiteLink sx={{ textDecoration: "underline" }}> */}
              {/* {translate('footer.confirm2')} */}
              <HashLink
                style={{
                  color: '#FFC425',
                  textDecoration: 'underline',
                  '&:hover': {
                    textDecoration: 'underline !important',
                  },
                }}
                to={`${pathConditions}#dataProtection`}
              >
                {translate('footer.confirm2')}
              </HashLink>
              {/* </WhiteLink> */}
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Divider sx={{ border: '1px solid rgba(247, 249, 250, 0.29)' }} />
      <Container>
        <Grid container sx={{ p: 4 }}>
          <Grid xs={12} md={6} item>
            <Typography variant="h4" sx={{ pr: { md: 5 }, fontWeight: 'bold' }}>
              {translate('footer.contact')}
            </Typography>
            <Typography
              variant="body1"
              align="justify"
              sx={{ pr: { md: 5 }, mt: 2, fontWeight: 'normal', color: 'common.white' }}
            >
              <Link
                href="mailto:slovakiana@nocka.sk?subject=Napíšte nám"
                sx={{
                  color: 'common.white',
                  textDecoration: 'underline',
                }}
              >
                slovakiana@nocka.sk
              </Link>
            </Typography>
            <Typography
              variant="body1"
              align="justify"
              sx={{ pr: { md: 5 }, fontWeight: 'normal', color: 'common.white' }}
            >
              Národné osvetové centrum
            </Typography>
            <Typography
              variant="body1"
              align="justify"
              sx={{ pr: { md: 5 }, fontWeight: 'normal', color: 'common.white' }}
            >
              Nám. SNP 12
            </Typography>
            <Typography
              variant="body1"
              align="justify"
              sx={{ pr: { md: 5 }, fontWeight: 'normal', color: 'common.white' }}
            >
              812 34 Bratislava
            </Typography>
          </Grid>
          <Grid xs={12} md={4} item sx={{ mt: { xs: 6, md: 0 } }}>
            <Typography variant="body1" sx={{ pr: { md: 5 }, mt: 1, fontWeight: 'normal', color: 'common.white' }}>
              <WhiteLink to={`${pathSlovakiana}?faq`}>{translate('footer.help')}</WhiteLink>
            </Typography>
            <Typography variant="body1" sx={{ pr: { md: 5 }, mt: 1, fontWeight: 'normal', color: 'common.white' }}>
              <WhiteLink to={pathInst}>{translate('footer.companies')}</WhiteLink>
            </Typography>
            <Typography variant="body1" sx={{ pr: { md: 5 }, mt: 1, fontWeight: 'normal', color: 'common.white' }}>
              <WhiteLink to={pathAccess}>{translate('footer.accesibility')}</WhiteLink>
            </Typography>
            <Typography variant="body1" sx={{ pr: { md: 5 }, mt: 1, fontWeight: 'normal', color: 'common.white' }}>
              <WhiteLink to={pathConditions}>{translate('footer.conditions')}</WhiteLink>
            </Typography>
          </Grid>
          <Grid xs={12} md={2} item sx={{ mt: { xs: 6, md: 0 } }}>
            <Box
              component="img"
              alt="europeana-stamp"
              src={europeanaStamp}
              sx={{ marginLeft: { md: 2, xs: 0 }, marginTop: { md: 4, xs: 0 }, maxHeight: 85 }}
            />
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Grid container sx={{ p: 4 }}>
          {/* <Logo sx={{ mb: 1, mx: 'auto' }} /> */}
          <Grid xs={12} md={6} item>
            © {year} POWERED BY
            {currentLang.getValue === 'skSK' ? (
              <Link href="https://brainit.sk" target="_blank" sx={{ ml: 0.5, fontWeight: 'bold' }}>
                BRAIN:IT
              </Link>
            ) : (
              <Link href="https://brainit.com" target="_blank" sx={{ ml: 0.5, fontWeight: 'bold' }}>
                BRAIN:IT
              </Link>
            )}
          </Grid>
        </Grid>
      </Container>
      <Divider />
      {/*
      <Container sx={{ pt: 10 }}>
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          <Grid item xs={12} sx={{ mb: 3 }}>
            <Logo sx={{ mx: { xs: 'auto', md: 'inherit' } }} />
          </Grid>

          <Grid item xs={8} md={3}>
            <Typography variant="body2" sx={{ pr: { md: 5 } }}>
              The starting point for your next project with Minimal UI Kit, built on the newest version of Material-UI
              ©, ready to be customized to your style.
            </Typography>

            <Stack
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{ mt: 5, mb: { xs: 5, md: 0 } }}
            >
              <SocialsButton sx={{ mx: 0.5 }} />
            </Stack>
          </Grid>

          <Grid item xs={12} md={7}>
            <Stack spacing={5} direction={{ xs: 'column', md: 'row' }} justifyContent="space-between">
              {LINKS.map((list) => (
                <Stack key={list.headline} spacing={2}>
                  <Typography component="p" variant="overline">
                    {list.headline}
                  </Typography>

                  {list.children.map((link) => (
                    <Link
                      to={link.href}
                      key={link.name}
                      color="inherit"
                      variant="body2"
                      component={RouterLink}
                      sx={{ display: 'block' }}
                    >
                      {link.name}
                    </Link>
                  ))}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Typography
          component="p"
          variant="body2"
          sx={{
            mt: 10,
            pb: 5,
            fontSize: 13,
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          © 2021. All rights reserved
        </Typography>
        </Container> */}
    </RootStyle>
  );
}
