import { useEffect, useMemo } from 'react';
import { matchRoutes, useLocation, useNavigate, useRoutes } from 'react-router-dom';
import i18next from 'i18next';
import useLocales from '../hooks/useLocales';
import MainLayout from '../layouts/MainLayout';
import routesConfig, { topRoutesConfig } from './config';

export default function Router() {

  const { pathname, search } = useLocation();
  const location = useLocation();
  const navigate = useNavigate();

  const { allLangs, currentLang } = useLocales();

  const ignoredLang = useMemo(() => [ "sk" ], []); // musia sa definovat manualne
  const languages = useMemo(() => allLangs.filter((lang) => !ignoredLang.includes(lang.value)).map((lang) => ({ ...lang, t: i18next.getFixedT(lang.value)})), [ allLangs, ignoredLang ]);
  const t = i18next.getFixedT("sk");

  const routes = useMemo(
    () =>
      routesConfig.flatMap((route) => [
        {
          path: route.pathT ? t(route.pathT) : route.path,
          element: route.element,
        },
        ...languages.map((lang) => ({
          path: `/${lang.value}${route.pathT ? lang.t(route.pathT) : route.path}`, // ${lang.t(route.path)}
          element: route.element,
        })),
      ]),
    [languages, t]
  );

  const topRoutes = useMemo(
    () =>
      topRoutesConfig.flatMap((route) => [
        {
          path: route.pathT ? t(route.pathT) : route.path,
          element: route.element,
        },
        ...languages.map((lang) => ({
          path: `/${lang.value}${route.pathT ? lang.t(route.pathT) : route.path}`, // ${lang.t(route.path)}
          element: route.element,
        })),
      ]),
    [languages, t]
  );

  const allRoutes = useMemo(() => [...routes, ...topRoutes], [routes, topRoutes]);

  useEffect(() => {
    const [{ route }] = matchRoutes(routes, location); // nesmie sa premenovat

    // console.log(route, pathname);

    let actualIndex = allRoutes.findIndex((troute) => troute.path === route?.path);
    const actualRoute = allRoutes[actualIndex];
    actualIndex =
      Math.floor(actualIndex / (ignoredLang.length + languages.length)) * (ignoredLang.length + languages.length);

    const ignoredIdx = ignoredLang.findIndex((ilang) => ilang === currentLang.value);
    const langIdx = languages.findIndex((lang) => lang.value === currentLang.value);

    const newRoute =
      ignoredIdx >= 0 ? routes[actualIndex + ignoredIdx] : routes[actualIndex + ignoredLang.length + langIdx];

    /* let path = pathname;
    languages.forEach((lang) => {
      // eslint-disable-next-line
      path = path.replace(`/${lang.value}/`, '/');
    });
    if (ignoredIdx < 0) {
      path = `/${currentLang.value}${path}`;
    } */
    if (actualRoute.path !== newRoute.path) {
      // console.log("nerovna sa");
      const doubleIdx = actualRoute.path.indexOf(':');
      // console.log(doubleIdx);
      let params = '';
      let newPath = newRoute.path;
      if (doubleIdx >= 0) {
        params = pathname.substring(doubleIdx);
        // console.log(params);
        const newDoubleIdx = newPath.indexOf(':');
        newPath = newPath.substring(0, newDoubleIdx) + params;
        // console.log(newPath);
      }
      navigate(`${newPath}${search}`, { replace: true });
    }
  }, [allRoutes, currentLang, languages, ignoredLang, pathname, search, navigate, location, routes]);

  return useRoutes([
    {
      path: '/',
      element: <MainLayout />,
      children: [...topRoutes, ...routes]
    }
  ]);
}