import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  annoucements: {
    totalCount: '',
    results: [],
  },
  currentAnnoucement: {},
};

const slice = createSlice({
  name: 'annoucement',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Annoucement
    getAnnoucementsSuccess(state, action) {
      state.isLoading = false;
      state.annoucements = action.payload;
    },

    logState(state, action) {
      console.log(state, action);
    },
  },
});

// Reducer
export default slice.reducer;

// GET
export function getAnnoucements(size, page, sortAttribute = '', sortDirection = 0, locale = "skSK") {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/announcements', {
        params: { size, page, sortAttribute, sortDirection, locale },
      });
      dispatch(slice.actions.getAnnoucementsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function logState() {
  dispatch(slice.actions.logState());
}
