import { createSlice } from '@reduxjs/toolkit';
import qs from 'qs';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  EDUs: {
    totalCount: '',
    results: [],
  },
  EDUCategories: {},
  prev: {},
  next: {},
};

const slice = createSlice({
  name: 'edu',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EDUs
    getEDUsSuccess(state, action) {
      state.isLoading = false;
      if (action.payload.loadMore && !action.payload.sortAttribute) {
        state.EDUs.totalCount = action.payload.totalCount;
        state.EDUs.hasMoreResults = action.payload.hasMoreResults;
        state.EDUs.results = [...state.EDUs.results, ...action.payload.results];
      } else {
        delete action.payload.sortAttribute;
        delete action.payload.loadMore;
        state.EDUs = action.payload;
      }
    },
    getNextSuccess(state, action) {
      state.isLoading = false;
      state.next = action.payload;
    },
    getPrevSuccess(state, action) {
      state.isLoading = false;
      state.prev = action.payload;
    },
    getEDUsCountSuccess(state, action) {
      state.isLoading = false;
      state.EDUs.totalCount = action.payload;
    },

    getCategoriesSuccess(state, action) {
      state.mapCategories = action.payload;
      state.isLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getArticlesSuccess } = slice.actions;

// ----------------------------------------------------------------------

export function getEDUs() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/edumaterials', { params: { size: 1000000 } });
      dispatch(slice.actions.getEDUsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getEDUListForInput(searchText, size) {
  return async () => {
    try {
      const response = await axios.get(`/edumaterial/search`, {
        params: {
          sortAttribute: 'rank',
          sortDirection: 1,
          size,
          page: 0,
          searchText: searchText?.replaceAll("\"", ""),
          searchByKeywords: !searchText?.includes("\""),
        },
        paramsSerializer: (params) => qs.stringify(params),
      });
      return response;
    } catch (error) {
      return dispatch(slice.actions.hasError(error));
    }
  };
}

export function getEDUsSearch({
  searchText,
  size,
  sortAttribute = 'created',
  sortDirection = 1,
  page,
  categoryKeys,
  tagKeys,
  loadMore,
  // searchByKeywords,
  signal,
  locale,
}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/edumaterial/search`, {
        params: {
          SortAttribute: sortAttribute,
          SortDirection: sortDirection,
          size,
          page: page - 1,
          categoryKeys,
          tagKeys,
          searchText: searchText?.replaceAll("\"", ""),
          searchByKeywords: !searchText?.includes("\""),
          locale,
        },
        signal,
        paramsSerializer: (params) => qs.stringify(params),
      });
      dispatch(slice.actions.getEDUsSuccess({ ...response.data, loadMore, sortAttribute }));
    } catch (error) {
      if (!signal.aborted) {
        dispatch(slice.actions.hasError(error));
      }
    }
  };
}

export function getEDUsCount({ searchText, categoryKeys, tagKeys }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/edumaterial/search/totalCount', {
        params: {
          categoryKeys,
          tagKeys,
          searchText: searchText?.replaceAll("\"", ""),
          searchByKeywords: !searchText?.includes("\""),
        },
        paramsSerializer: (params) => qs.stringify(params),
      });
      dispatch(slice.actions.getEDUsCountSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// export function getEDU(key) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.get(`/edumaterials/${key}`);
//       dispatch(slice.actions.getCurrentEduSuccess(response.data));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }
