import { InputSelectIcon } from './CustomIcons';

// ----------------------------------------------------------------------

export default function Select() {
  return {
    MuiSelect: {
      defaultProps: {
        IconComponent: InputSelectIcon,
      },
      styleOverrides: {
        '& icon': {
          right: '20px',
        },
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            // border: '1px solid rgba(0, 0, 0, 0.3)',
          },
        },
      },
    },
  };
}
