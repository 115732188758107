import { useEffect } from 'react';
// routes

import Router from './routes/Router';
// theme
import ThemeProvider from './theme';
// components
import ThemeSettings from './components/settings';
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import { SnackbarUtilsConfigurator } from './utils/snackbar';
import { GAEvent } from './components/GoogleAnalytics';
import useLocales from './hooks/useLocales';
import AlternateLinks from './alternateLinks';
import AddFacebookPixelToHead from './addFacebookPixelToHead';
// ----------------------------------------------------------------------

export default function App() {
  const { translate } = useLocales();
  useEffect(() => {
    const scrollHandle = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const { scrollHeight } = document.documentElement;

      const height20 = Math.floor(scrollHeight * 0.23);
      const height25 = Math.floor(scrollHeight * 0.25);
      const height45 = Math.floor(scrollHeight * 0.48);
      const height50 = Math.floor(scrollHeight * 0.5);

      const height70 = Math.floor(scrollHeight * 0.73);
      const height75 = Math.floor(scrollHeight * 0.75);
      const height90 = Math.floor(scrollHeight * 0.9);

      if (scrollTop > height20 && scrollTop < height25) {
        GAEvent('Scroll Depth', 'Page Scrolled Depth', '25%');
      } else if (scrollTop > height45 && scrollTop < height50) {
        GAEvent('Scroll Depth', 'Page Scrolled Depth', '50%');
      } else if (scrollTop > height70 && scrollTop < height75) {
        GAEvent('Scroll Depth', 'Page Scrolled Depth', '75%');
      } else if (scrollTop >= height90) {
        GAEvent('Scroll Depth', 'Page Scrolled Depth', '90%');
      }
    };
    window.addEventListener('scroll', scrollHandle);
    return () => {
      window.removeEventListener('scroll', scrollHandle);
    };
  }, []);
  useEffect(() => {
    if (navigator.userAgent.match(/SamsungBrowser/i)) {
      // eslint-disable-next-line no-alert
      alert(
        `${translate('default.samsungAlert1')} ${translate('default.samsungAlert2')}\n\n${translate(
          'default.samsungAlert3'
        )}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <NotistackProvider>
            <SnackbarUtilsConfigurator />
            <ProgressBarStyle />
            <ChartStyle />
            <ScrollToTop />
            <Router />
            <AlternateLinks />
            <AddFacebookPixelToHead />
          </NotistackProvider>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
