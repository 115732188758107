import { Outlet } from 'react-router-dom';
import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import axios from 'axios';
import CustomHeader from '../components/custom-one-use/CustomHeader';
import CustomFooter from '../components/custom-one-use/CustomFooter';
import PageHealthCheck from '../views/PageHealthCheck';
import CookieBanner from '../components/CookieBanner/CookieBanner';

export default function MainLayout() {
  const [status, setStatus] = useState('Healthy');

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/healthcheck`)
      .then((resp) => {
        setStatus(resp.data);
      })
      .catch(() => {
        setStatus('No connection');
      });
  }, []);

  return (
    <>

      <Stack sx={{ minHeight: '100vh', backgroundColor: 'background.paper' }}>
        <CustomHeader />
        {status !== 'Healthy' && <PageHealthCheck />}
        {status === 'Healthy' && <Outlet />}
      </Stack>
      <Stack sx={{ minHeight: 0 }}>
        {/* <Box sx={{ flexGrow: 1, minHeight: 1 }} /> */}

        <CookieBanner />
        <CustomFooter />
      </Stack>
    </>
  );
}
