import { createSlice } from '@reduxjs/toolkit';
import qs from 'qs';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isLoadinngKO: false,
  error: null,
  culturalObject: {
    totalCount: '',
    results: [],
  },
  similarObjects: {
    results: [],
  },
  relatedArticles: [],
  randomFolder: {},
  currentCO: {},
  digitalObjects: [],
  suggested: [],
  suggestedOne: null,
  prev: null,
  next: null,
  nextIndex: null,
  prevIndex: null,
};

const slice = createSlice({
  name: 'culturalObject',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    startLoadingKO(state) {
      state.isLoadingKO = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Cultural Object
    getCOListSuccess(state, action) {
      if (action.payload.loadMore && !action.payload.sortAttribute) {
        state.culturalObject.totalCount = action.payload.totalCount;
        state.culturalObject.hasMoreResults = action.payload.hasMoreResults;
        state.culturalObject.results = [...state.culturalObject.results, ...action.payload.results];
      } else {
        delete action.payload.sortAttribute;
        delete action.payload.loadMore;
        state.culturalObject = action.payload;
      }
      state.currentCO = {};
      state.isLoadingKO = false;
    },
    getCOCountSuccess(state, action) {
      state.isLoading = false;
      state.culturalObject.totalCount = action.payload;
    },
    getSuggestedListSuccess(state, action) {
      state.isLoading = false;
      state.suggested = action.payload.results;
    },
    getSuggestedSuccess(state, action) {
      state.isLoading = false;
      state.suggestedOne = action.payload;
    },
    getCurrentCOSuccess(state, action) {
      state.isLoading = false;
      state.currentCO = action.payload;
    },
    clearDO(state) {
      state.digitalObjects = [];
    },
    getDOSuccess(state, action) {
      state.isLoading = false;
      const arr = state.digitalObjects ? state.digitalObjects.map((e) => e.id) : [];
      // Happy debbduging
      // action.payload.forEach((e) => {
      const e = action.payload;
      if (!arr.includes(e.id)) {
        arr.push(e.id);
        const dobj = e;
        // delete dobj.id;
        state.digitalObjects.push(...dobj.content.map((f) => ({ ...f, id: e.id })));
      }
      // });
      // state.digitalObjects.push(...action.payload.content);
    },

    getRelatedArticlesSuccess(state, action) {
      state.isLoading = false;
      state.relatedArticles = action.payload;
    },
    clearRelatedArticles(state) {
      state.relatedArticles = [];
    },
    getRandomFolderSuccess(state, action) {
      state.isLoading = false;
      state.randomFolder = action.payload;
    },
    getSimilarSuccess(state, action) {
      state.isLoading = false;
      state.similarObjects = action.payload;
    },
    logState(state, action) {
      console.log(state, action);
    },
    getAdjacentSuccess(state, action) {
      state.prev = action.payload.previous;
      state.next = action.payload.next;
      state.prevIndex = action.payload?.previousIndex;
      state.nextIndex = action.payload?.nextIndex;
    },
  },
});

export default slice.reducer;
// Actions
export const { clearRelatedArticles } = slice.actions;

// GET
export function getCOList(size, page, sortAttribute = '', sortDirection = 0) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/culturalobjects', {
        params: { size, page, sortAttribute, sortDirection },
      });
      dispatch(slice.actions.getCOListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRelatedArticles({ key, locale = 'skSK' }) {
  return async () => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/culturalobject/relatedarticles/${key}`, {
        params: { locale },
      });
      dispatch(slice.actions.getRelatedArticlesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCOByKey(key) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/culturalobject/${key}`);
      dispatch(slice.actions.getCurrentCOSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDOByKey(key) {
  return async () => {
    try {
      const response = await axios.get(`/digitalobject/${key}`);
      return dispatch(slice.actions.getDOSuccess({ ...response.data, id: key }));
    } catch (error) {
      return dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearDO() {
  return async () => {
    try {
      return dispatch(slice.actions.clearDO());
    } catch (error) {
      return dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSimilarObject(key, onlyWithImage, { locale }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/culturalobjects/similar`, {
        params: {
          key,
          onlyWithImage,
          size: 12,
          page: 0,
          locale,
        },
      });
      dispatch(slice.actions.getSimilarSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// pretoze to nie je koncovy parameter
// eslint-disable-next-line
export function getCOListForInput(searchText, size, searchByKeywords = true, onlyWithImage = true) {
  return async () => {
    try {
      const response = await axios.get(`/culturalobject/search`, {
        params: {
          sortAttribute: 'rank',
          sortDirection: 1,
          size,
          page: 0,
          searchText: searchText?.replaceAll('"', ''),
          searchByKeywords: !searchText?.includes('"'),
          onlyWithImage,
        },
        paramsSerializer: (params) => qs.stringify(params),
      });
      return response;
    } catch (error) {
      return dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSuggestedOne(key) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/folder/${key}`);
      return dispatch(slice.actions.getSuggestedSuccess({ ...response.data }));
    } catch (error) {
      return dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRandomFolder(locale = 'skSK') {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/folder/random`, { params: { locale } });
      dispatch(slice.actions.getRandomFolderSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCOSuggested(page, size, locale) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/folders`, {
        params: {
          page,
          size,
          showInCarousel: true,
          sortAttribute: 'order',
          sortDirection: 0,
          folderType: 1,
          locale,
        },
        paramsSerializer: (params) => qs.stringify(params),
      });
      return dispatch(slice.actions.getSuggestedListSuccess({ ...response.data }));
    } catch (error) {
      return dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCOListSearch({
  searchText,
  size,
  sortAttribute,
  sortDirection,
  page,
  categoryKeys,
  tagKeys,
  loadMore,
  onlyWithImage,
  onlyDownloadable,
  searchSimilarNames,
  // searchByKeywords,
  originDateFrom,
  originDateTo,
  signal,
  locale,
}) {
  return async () => {
    dispatch(slice.actions.startLoadingKO());
    try {
      const response = await axios.get(`/culturalobject/search`, {
        params: {
          sortAttribute,
          sortDirection,
          size,
          // size: loadMore ? size * page : size,
          // size: sortAttribute  ? size * page : size,
          page: page - 1,
          // page: sortAttribute  ? 0 : page - 1,
          categoryKeys,
          tagKeys,
          searchSimilarNames,
          searchText: searchText?.replaceAll('"', ''),
          searchByKeywords: !searchText?.includes('"'),
          onlyWithImage,
          onlyDownloadable,
          originDateFrom,
          originDateTo,
          locale,
        },
        signal,
        paramsSerializer: (params) => qs.stringify(params),
      });
      dispatch(slice.actions.getCOListSuccess({ ...response.data, sortAttribute, loadMore }));
    } catch (error) {
      if (!signal.aborted) {
        dispatch(slice.actions.hasError(error));
      }
    }
  };
}

export function getCOListSearchSimilar({
  searchText,
  size,
  sortAttribute,
  sortDirection,
  page,
  categoryKeys,
  tagKeys,
  onlyWithImage,
  onlyDownloadable,
  // searchByKeywords,
  originDateFrom,
  originDateTo,
  signal,
  locale,
}) {
  return async () => {
    dispatch(slice.actions.startLoadingKO());
    try {
      const response = await axios.get(`/culturalobject/search`, {
        params: {
          sortAttribute,
          sortDirection,
          size,
          // size: loadMore ? size * page : size,
          // size: sortAttribute  ? size * page : size,
          page: page - 1,
          // page: sortAttribute  ? 0 : page - 1,
          categoryKeys,
          tagKeys,
          searchText: searchText?.replaceAll('"', ''),
          searchByKeywords: false,
          onlyWithImage,
          onlyDownloadable,
          originDateFrom,
          originDateTo,
          SearchSimilarNames: true,
          locale,
        },
        signal,
        paramsSerializer: (params) => qs.stringify(params),
      });
      dispatch(slice.actions.getSimilarSuccess(response.data));
    } catch (error) {
      if (!signal.aborted) {
        dispatch(slice.actions.hasError(error));
      }
    }
  };
}

export function getCOCount({
  searchText,
  categoryKeys,
  tagKeys,
  // searchByKeywords = true,
  onlyWithImage,
  onlyDownloadable,
  originDateFrom,
  originDateTo,
  signal,
}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/culturalObject/search/totalCount', {
        params: {
          categoryKeys,
          tagKeys,
          searchText: searchText?.replaceAll('"', ''),
          searchByKeywords: !searchText?.includes('"'),
          onlyWithImage,
          onlyDownloadable,
          originDateFrom,
          originDateTo,
        },
        signal,
        paramsSerializer: (params) => qs.stringify(params),
      });
      dispatch(slice.actions.getCOCountSuccess(response.data));
    } catch (error) {
      if (!signal.aborted) {
        dispatch(slice.actions.hasError(error));
      }
    }
  };
}

export function getCOAdjacent(
  {
    index,
    searchText,
    sortAttribute,
    sortDirection,
    searchSimilarNames,
    categoryKeys,
    tagKeys,
    onlyWithImage,
    onlyDownloadable,
    originDateFrom,
    originDateTo,
    // searchByKeywords,
    signal,
  } /* ,
  index,
  searchText,
  sortAttribute,
  sortDirection,
  categoryKeys,
  tagKeys,
  onlyWithImage,
  onlyDownloadable,
  originDateFrom,
  originDateTo */
) {
  return async () => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/culturalobject/search/adjacent`, {
        params: {
          index,
          sortAttribute,
          sortDirection,
          categoryKeys,
          tagKeys,
          searchText: searchText?.replaceAll('"', ''),
          searchByKeywords: !searchText?.includes('"'),
          onlyWithImage,
          onlyDownloadable,
          originDateFrom,
          originDateTo,
          searchSimilarNames,
        },
        signal,
        paramsSerializer: (params) => qs.stringify(params),
      });
      dispatch(slice.actions.getAdjacentSuccess(response.data));
    } catch (error) {
      //  if (!signal?.aborted) {
      dispatch(slice.actions.hasError(error));
      // }
    }
  };
}

export function getVideoAdjacent(
  {
    index,
    searchText,
    sortAttribute,
    sortDirection,
    categoryKeys,
    tagKeys,
    onlyWithImage,
    onlyDownloadable,
    originDateFrom,
    originDateTo,
    signal,
    // searchByKeywords,
  } /* ,
  index,
  searchText,
  sortAttribute,
  sortDirection,
  categoryKeys,
  tagKeys,
  onlyWithImage,
  onlyDownloadable,
  originDateFrom,
  originDateTo */
) {
  return async () => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/videolibrary/search/adjacent`, {
        params: {
          index,
          sortAttribute,
          sortDirection,
          categoryKeys,
          tagKeys,
          searchText: searchText?.replaceAll("\"", ""),
          searchByKeywords: !searchText?.includes("\""),
          onlyWithImage,
          onlyDownloadable,
          originDateFrom,
          originDateTo,
        },
        signal,
        paramsSerializer: (params) => qs.stringify(params),
      });
      dispatch(slice.actions.getAdjacentSuccess(response.data));
    } catch (error) {
      //  if (!signal?.aborted) {
      dispatch(slice.actions.hasError(error));
      // }
    }
  };
}

export function getEDUAdjacent(
  {
    index,
    searchText,
    sortAttribute,
    sortDirection,
    categoryKeys,
    tagKeys,
    onlyWithImage,
    onlyDownloadable,
    originDateFrom,
    originDateTo,
    // searchByKeywords,
    signal,
  } /* ,
  index,
  searchText,
  sortAttribute,
  sortDirection,
  categoryKeys,
  tagKeys,
  onlyWithImage,
  onlyDownloadable,
  originDateFrom,
  originDateTo */
) {
  return async () => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/edumaterial/search/adjacent`, {
        params: {
          index,
          sortAttribute,
          sortDirection,
          categoryKeys,
          tagKeys,
          searchText: searchText?.replaceAll("\"", ""),
          searchByKeywords: !searchText?.includes("\""),
          onlyWithImage,
          onlyDownloadable,
          originDateFrom,
          originDateTo,
        },
        signal,
        paramsSerializer: (params) => qs.stringify(params),
      });
      dispatch(slice.actions.getAdjacentSuccess(response.data));
    } catch (error) {
      //  if (!signal?.aborted) {
      dispatch(slice.actions.hasError(error));
      // }
    }
  };
}

export function getCSAdjacent({
  index,
  searchText,
  sortAttribute = 'created',
  sortDirection,
  categoryKeys,
  tagKeys,
  onlyUnBlackened,
  signal,
}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/censussheet/search/adjacent`, {
        params: {
          index,
          sortAttribute,
          sortDirection,
          categoryKeys,
          tagKeys,
          searchText: searchText?.replaceAll("\"", ""),
          searchByKeywords: !searchText?.includes("\""),
          onlyUnBlackened,
        },
        signal,
        paramsSerializer: (params) => qs.stringify(params),
      });
      dispatch(slice.actions.getAdjacentSuccess(response.data));
    } catch (error) {
      // if (!signal?.aborted) {
      dispatch(slice.actions.hasError(error));
      //  }
    }
  };
}

export function logState() {
  dispatch(slice.actions.logState());
}
