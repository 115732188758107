import { useEffect } from 'react';
import { useCookies } from 'react-cookie';

const AddFacebookPixelToHead = () => {
  const [cookies] = useCookies();

  useEffect(() => {
    if (cookies?.cmplz_cookies_functional === 'granted') {
      const script = document.createElement('script');
      script.innerHTML = `
        !function(f, b, e, v, n, t, s) {
          if (f.fbq) return; n = f.fbq = function () {
            n.callMethod ?
              n.callMethod.apply(n, arguments) : n.queue.push(arguments)
          };
          if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
          n.queue = []; t = b.createElement(e); t.async = !0;
          t.src = v; s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s)
        }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '823834774903110');
        fbq('track', 'PageView');
      `;
      document.head.appendChild(script);

      const noscript = document.createElement('noscript');
      const img = document.createElement('img');
      img.setAttribute('height', '1');
      img.setAttribute('width', '1');
      img.style.display = 'none';
      img.src = 'https://www.facebook.com/tr?id=823834774903110&ev=PageView&noscript=1';
      noscript.appendChild(img);
      document.head.appendChild(noscript);
      document.head.appendChild(noscript);
    }
  }, [cookies?.cmplz_cookies_functional]);
};

export default AddFacebookPixelToHead;
