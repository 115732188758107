import { lazy, Suspense } from 'react';
import { Navigate } from 'react-router';
import { Box } from '@mui/material';
import Iconify from '../components/Iconify';

import LoadingScreen from '../components/LoadingScreen';
import { PATHS } from './paths';
import catalogSVG from '../assets/images/aboutImgs/catalog.svg';
import catalogSVGW from '../assets/images/aboutImgs/catalog-w.svg';
import videoSVG from '../assets/images/aboutImgs/video.svg';
import videoSVGW from '../assets/images/aboutImgs/video-w.svg';
import mapSVG from '../assets/images/aboutImgs/map.svg';
import mapSVGW from '../assets/images/aboutImgs/map-w.svg';
import articles from '../assets/images/aboutImgs/articles.svg';
import articlesW from '../assets/images/aboutImgs/articles-w.svg';
import notesSVG from '../assets/images/aboutImgs/scitanie.svg';
import notesSVGW from '../assets/images/aboutImgs/scitanie-w.svg';
import edu from '../assets/images/aboutImgs/edu.svg';
import eduW from '../assets/images/aboutImgs/edu-w.svg';
import info from '../assets/images/aboutImgs/info-black.svg';
import infoW from '../assets/images/aboutImgs/info-white.svg';

const Loadable = (Component) => (props) => {
  const isDashboard = window.location?.pathname?.includes?.('/dashboard');

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};
const Page404 = Loadable(lazy(() => import('../views/Page404')));
const PageHome = Loadable(lazy(() => import('../views/Home/PageHome')));
const CultureObjects = Loadable(lazy(() => import('../views/CulturalObject/CultureObjects')));
const CountingSheets = Loadable(lazy(() => import('../views/CountingSheets/CountingSheets')));
const Multimedia = Loadable(lazy(() => import('../views/Multimedia/Multimedia')));
const Articles = Loadable(lazy(() => import('../views/Articles/Articles')));
const Map = Loadable(lazy(() => import('../views/Map/Map')));
const CultureObjectDetail = Loadable(lazy(() => import('../views/CulturalObject/CultureObjectDetail')));
// const MultimediaDetail = Loadable(lazy(() => import('../views/Multimedia/MultimediaDetail')));
// const CountingSheetsDetail = Loadable(lazy(() => import('../views/CountingSheets/CountingSheetsDetail')));
const ArticlesDetail = Loadable(lazy(() => import('../views/Articles/ArticlesDetail')));
const AboutSlovakianaPage = Loadable(lazy(() => import('../views/AboutSlovakiana/AboutSlovakianaPage')));
const Favorite = Loadable(lazy(() => import('../views/Favorite/Favorite')));
const EduMaterialsPage = Loadable(lazy(() => import('../views/EduMaterialsPage/EduMaterialsPage')));
const Accessibility = Loadable(lazy(() => import('../views/TextPages/Accessibility')));
const AuthorRights = Loadable(lazy(() => import('../views/TextPages/AuthorRights')));
const Companies = Loadable(lazy(() => import('../views/TextPages/Companies')));
const Conditions = Loadable(lazy(() => import('../views/TextPages/Conditions')));
const CompanyDetail = Loadable(lazy(() => import('../views/TextPages/CompanyDetail')));
// const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));

const ICON_SIZE = {
  width: 22,
  height: 22,
};

const routesConfig = [
  {
    title: 'default.home',
    path: '',
    element: <Navigate to={PATHS.HOME} replace />,
    display: false,
  },
  {
    title: 'default.home',
    icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
    path: PATHS.HOME,
    pathT: 'urls.home',
    element: <PageHome />,
    display: false,
  },

  {
    title: 'default.cultureObjects',
    iconW: (
      <Box sx={{ height: '48px', width: '48px' }}>
        <img src={catalogSVGW} alt={`default.cultureObjects icon`} />
      </Box>
    ),
    icon: (
      <Box sx={{ height: '48px', width: '48px' }}>
        <img src={catalogSVG} alt={`default.cultureObjects icon`} />
      </Box>
    ),
    // icon: <Iconify icon={'catalogSVG'} {...ICON_SIZE} />,
    path: PATHS.CATALOGUE,
    pathT: 'urls.co',
    element: <CultureObjects />,
    display: true,
  },
  {
    title: 'default.multimedia',
    // icon: <Iconify icon={'mdi:multimedia'} {...ICON_SIZE} />,
    iconW: (
      <Box sx={{ height: '48px', width: '48px' }}>
        <img src={videoSVGW} alt={`default.multimedia icon`} />
      </Box>
    ),
    icon: (
      <Box sx={{ height: '48px', width: '48px' }}>
        <img src={videoSVG} alt={`default.multimedia icon`} />
      </Box>
    ),
    path: PATHS.VIDEO,
    pathT: 'urls.video',
    element: <Multimedia />,
    display: true,
  },
  {
    title: 'default.map',
    // icon: <Iconify icon={'emojione-monotone:castle'} {...ICON_SIZE} />,
    iconW: (
      <Box sx={{ height: '48px', width: '48px' }}>
        <img src={mapSVGW} alt={`default.map icon`} />
      </Box>
    ),
    icon: (
      <Box sx={{ height: '48px', width: '48px' }}>
        <img src={mapSVG} alt={`default.map icon`} />
      </Box>
    ),
    path: PATHS.MAP,
    pathT: 'urls.map',
    element: <Map />,
    display: true,
  },
  {
    title: 'default.map',
    // icon: <Iconify icon={'emojione-monotone:castle'} {...ICON_SIZE} />,
    iconW: (
      <Box sx={{ height: '48px', width: '48px' }}>
        <img src={mapSVGW} alt={`default.map icon`} />
      </Box>
    ),
    icon: (
      <Box sx={{ height: '48px', width: '48px' }}>
        <img src={mapSVG} alt={`default.map icon`} />
      </Box>
    ),
    path: PATHS.OLD_MAP,
    element: <Map />,
    display: false,
  },
  {
    title: 'default.articles',
    // icon: <Iconify icon={'akar-icons:chat-dots'} {...ICON_SIZE} />,
    iconW: (
      <Box sx={{ height: '48px', width: '48px' }}>
        <img src={articlesW} alt={`default.article icon`} />
      </Box>
    ),
    icon: (
      <Box sx={{ height: '48px', width: '48px' }}>
        <img src={articles} alt={`default.article icon`} />
      </Box>
    ),
    path: PATHS.ARTICLES,
    pathT: 'urls.articles',
    element: <Articles />,
    display: true,
  },
  {
    title: 'default.articles_blog',
    icon: <Iconify icon={'akar-icons:chat-dots'} {...ICON_SIZE} />,
    path: PATHS.ARTICLES_BLOG,
    element: <Articles />,
    display: false,
  },
  {
    title: 'default.articles_newsfeed',
    icon: <Iconify icon={'akar-icons:chat-dots'} {...ICON_SIZE} />,
    path: PATHS.ARTICLES_NEWSFEED,
    element: <Articles />,
    display: false,
  },
  {
    title: 'default.countSheets',
    // icon: <Iconify icon={'bi:list-check'} {...ICON_SIZE} />,
    iconW: (
      <Box sx={{ height: '48px', width: '48px' }}>
        <img src={notesSVGW} alt={`default.countSheets icon`} />
      </Box>
    ),
    icon: (
      <Box sx={{ height: '48px', width: '48px' }}>
        <img src={notesSVG} alt={`default.countSheets icon`} />
      </Box>
    ),
    path: PATHS.COUNTING_SHEETS,
    pathT: 'urls.sheets',
    element: <CountingSheets />,
    display: true,
  },

  {
    title: 'default.cultureObjectDetail',
    path: PATHS.CATALOGUE_DETAIL,
    pathT: 'urls.coId',
    element: <CultureObjectDetail />,
    display: false,
  },
  {
    title: 'default.cultureObjectDetail',
    path: PATHS.CATALOGUE_DETAIL,
    pathT: 'urls.coIdSimilar',
    element: <CultureObjectDetail />,
    display: false,
  },
  {
    title: 'default.multimediaDetail',
    path: PATHS.VIDEO_DETAIL,
    pathT: 'urls.videoId',
    element: <CultureObjectDetail />,
    display: false,
  },
  {
    title: 'default.multimediaDetail',
    path: PATHS.VIDEO_DETAIL,
    pathT: 'urls.videoIdSimilar',
    element: <CultureObjectDetail />,
    display: false,
  },
  {
    title: 'default.funny',
    path: PATHS.EDU_MAT_DETAIL,
    pathT: 'urls.eduId',
    element: <CultureObjectDetail />,
    display: false,
  },
  {
    title: 'default.countingSheetsDetail',
    path: PATHS.COUNTING_SHEETS_DETAIL,
    pathT: 'urls.sheetsId',
    element: <CultureObjectDetail />,
    display: false,
  },
  {
    title: 'default.articlesDetail',
    path: PATHS.ARTICLES_DETAIL,
    pathT: 'urls.articleIdName',
    element: <ArticlesDetail />,
    display: false,
  },
  {
    title: 'default.articlesDetail',
    path: PATHS.ARTICLES_DETAIL_NONAME,
    pathT: 'urls.articlesId',
    element: <ArticlesDetail />,
    display: false,
  },
  {
    title: 'default.institutionDetial',
    path: PATHS.INSTITUTION_DETAIL,
    pathT: 'urls.institutionsId',
    element: <CompanyDetail />,
    display: false,
  },
  {
    title: 'default.map',
    icon: <Iconify icon={'emojione-monotone:castle'} {...ICON_SIZE} />,
    path: PATHS.MAP_DETAIL,
    pathT: 'urls.mapIdName',
    element: <ArticlesDetail />,
    display: false,
  },
  {
    title: 'default.map',
    icon: <Iconify icon={'emojione-monotone:castle'} {...ICON_SIZE} />,
    path: PATHS.OLD_MAP_DETAIL,
    pathT: 'urls.oldMapIdName',
    element: <ArticlesDetail />,
    display: false,
  },
  {
    title: 'default.map',
    icon: <Iconify icon={'emojione-monotone:castle'} {...ICON_SIZE} />,
    path: PATHS.MAP_DETAIL_NONAME,
    pathT: 'urls.mapId',
    element: <ArticlesDetail />,
    display: false,
  },
  {
    title: 'default.map',
    icon: <Iconify icon={'emojione-monotone:castle'} {...ICON_SIZE} />,
    path: PATHS.OLD_MAP_DETAIL_NONAME,
    pathT: 'urls.oldMapId',
    element: <ArticlesDetail />,
    display: false,
  },
  {
    title: 'default.conditions',
    path: PATHS.CONDITIONS,
    pathT: 'urls.conditions',
    element: <Conditions />,
    display: false,
  },
  {
    title: 'default.companies',
    path: PATHS.INSTITUTION,
    pathT: 'urls.institutions',
    element: <Companies />,
    display: false,
  },
  {
    title: 'default.acessibility',
    path: PATHS.ACCESSIBILITY,
    pathT: 'urls.accesibility',
    element: <Accessibility />,
    display: false,
  },
  {
    title: 'default.authorRights',
    path: PATHS.AUTHOR_RIGHTS,
    pathT: 'urls.authorRights',
    element: <AuthorRights />,
    display: false,
  },
  {
    title: 'default.funny',
    path: PATHS.EDU_MAT,
    element: <EduMaterialsPage />,
    pathT: 'urls.edu',
    display: false,
  },
  {
    title: 'default.aboutSlovakiana',
    path: PATHS.SLOVAKIANA,
    pathT: 'urls.slovakiana',
    element: <AboutSlovakianaPage />,
    display: false,
  },
  {
    title: 'default.favorites',
    path: PATHS.FAVORITE,
    pathT: 'urls.favorites',
    element: <Favorite />,
    display: false,
  },
  /* {
    title: 'docasne',
    path: 'docasne',
    element: <ComingSoon />,
    display: false,
  }, */
  {
    title: 'default.notFound',
    path: '*',
    pathT: 'urls.other',
    element: <Page404 />,
    display: false,
  },
];

const topRoutesConfig = [
  {
    title: 'default.favorites',
    icon: (
      <Iconify
        icon={'ant-design:heart-filled'}
        color="#D16666"
        sx={{ mr: 1, position: 'relative', verticalAlign: 'bottom' }}
        {...ICON_SIZE}
      />
    ),
    icon2: (
      <Iconify
        icon={'ant-design:heart-outlined'}
        color="black"
        sx={{ mr: 1, position: 'relative', verticalAlign: 'bottom' }}
        {...ICON_SIZE}
      />
    ),
    path: PATHS.FAVORITE,
    pathT: 'urls.favorites',
    element: <Favorite />,
    showIcon: true,
  },

  {
    title: 'default.funny',
    // icon: <Iconify icon={'fa:cloud-download'} {...ICON_SIZE} />,
    iconW: (
      <Box sx={{ height: '48px', width: '48px' }}>
        <img src={eduW} alt={`default.countSheets icon`} />
      </Box>
    ),
    icon: (
      <Box sx={{ height: '48px', width: '48px' }}>
        <img src={edu} alt={`default.countSheets icon`} />
      </Box>
    ),
    path: PATHS.EDU_MAT,
    pathT: 'urls.edu',
    element: <EduMaterialsPage />,
    showIcon: false,
  },
  {
    title: 'default.aboutSlovakiana',
    // icon: <Iconify icon={'akar-icons:info-fill'} {...ICON_SIZE} />,
    iconW: (
      <Box sx={{ height: '48px', width: '48px' }}>
        <img src={infoW} alt={`default.aboutSlovakiana icon`} />
      </Box>
    ),
    icon: (
      <Box sx={{ height: '48px', width: '48px' }}>
        <img src={info} alt={`default.aboutSlovakiana icon`} />
      </Box>
    ),
    path: PATHS.SLOVAKIANA,
    pathT: 'urls.slovakiana',
    element: <AboutSlovakianaPage />,
    showIcon: false,
  },
];

export { topRoutesConfig };

export default routesConfig;
