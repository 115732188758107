import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Button } from '@mui/material';
import useLocales from '../../../hooks/useLocales';

CustomButton.propTypes = {
  text: PropTypes.string,
  primary: PropTypes.bool,
  action: PropTypes.func,
  onClick: PropTypes.func,
  style: PropTypes.object,
  medium: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default function CustomButton({ text, action, onClick, primary, style, medium, disabled }) {
  const theme = useTheme();
  const { translate } = useLocales();

  let bgColor = primary ? theme.palette.primaryButton.main : theme.palette.secondary.main;
  let txColor = primary ? theme.palette.primaryButton.contrastText : theme.palette.secondary.contrastText;
  if (disabled) {
    bgColor = primary ? theme.palette.primaryButton.disabled : theme.palette.secondary.disabled;
    txColor = theme.palette.grey[400];
  }

  const clickHandler = (e) => {
    if (action) action(e);
    if (onClick) onClick(e);
  };

  return (
    <Button
      style={{
        backgroundColor: bgColor,
        color: txColor,
        ...style,
      }}
      variant="contained"
      sx={{ position: 'relative', textTransform: 'none', height: 'auto', ...style }}
      onClick={clickHandler}
      size={medium ? 'medium' : 'large'}
      disabled={disabled}
    >
      {translate(text)}
    </Button>
  );
}
